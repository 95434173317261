import "./MiniBox.css";
import { useState } from "react";

function MiniBox({
    slot,
    product,
    label,
    setShowPopup,
    setPopupData,
    setProductPopup,
}) {
    const rows = 8;
    const columns = 10;
    const totalShelves = 2; // Número de prateleiras

    const getLabel = (index) => {
        const row = Math.floor(index / columns);
        const col = index % columns;
        const rowLabel = String.fromCharCode(65 + row);
        const colLabel = col + 1;
        return `${rowLabel}${colLabel}`;
    };

    return (
        <div
            className={`admin-box ${false && !false ? "open" : ""} ${
                false ? "shaking" : ""
            } ${false ? "openfast" : ""}`}
        >
            {product ? (
                <div
                    className={`mini-box-qtty ${slot?.qtty < 1 ? "empty" : ""}`}
                >
                    {slot?.qtty || 0}/{Math.ceil(product?.uniqueOrders * 0.5)}
                </div>
            ) : (
                <></>
            )}
            {product ? (
                <div className="box">
                    <img
                        className="admin-box-product"
                        src={`https://orizom.com.br/uploads/thumbs/${product?.photos[0]}`}
                        onClick={() => {
                            setPopupData(slot);
                            setProductPopup(true);
                        }}
                    />
                    <div className="side front">
                        <div className="side-label">
                            {product?.sku}
                            <img src="/img/starbox/barcode.png" />
                        </div>
                    </div>
                    <div className="side left" />
                    <div className="side back"></div>
                    <div className="side right"></div>
                    <div className="side bottom"></div>
                    <div className="flap front"></div>
                    <div className="flap back"></div>
                    <div className="flap left"></div>
                    <div className="flap right"></div>
                </div>
            ) : (
                <div
                    className="box-add-product"
                    onClick={() => {
                        setPopupData(slot);
                        setProductPopup(true);
                    }}
                >
                    +
                </div>
            )}
            <div className="slot-label">{label}</div>
            <div className={`slot-buttons ${product ? "hasproduct" : ""}`}>
                {product ? (
                    <>
                        <div className="slot-btn qtty-slot">
                            <span
                                className="qtty-slot-btn"
                                onClick={() => {
                                    if (slot.qtty < 1) return;
                                    fetch("/api/starbox/updateslot", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ...slot,
                                            qtty: slot.qtty - 1,
                                        }),
                                    });
                                    setProductPopup(false);
                                }}
                            >
                                -
                            </span>
                            <span className="qtty-slot-numer">
                                {slot?.qtty || 0}/
                                {Math.ceil(product?.uniqueOrders * 0.5)}
                            </span>
                            <span
                                className="qtty-slot-btn plus"
                                onClick={() => {
                                    fetch("/api/starbox/updateslot", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ...slot,
                                            qtty: slot.qtty + 1,
                                        }),
                                    });
                                    setProductPopup(false);
                                }}
                            >
                                +
                            </span>
                        </div>
                        <div
                            className="slot-btn"
                            onClick={() => {
                                fetch("/api/starbox/createtask", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        body: { x: slot?.x, y: slot?.y },
                                        text: `Buscar caixa em ${slot?.label}`,
                                        type: 0,
                                    }),
                                });
                            }}
                        >
                            Buscar caixa
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div
                    className="slot-btn"
                    onClick={() => {
                        setPopupData(slot);
                        setShowPopup(true);
                    }}
                >
                    Configurar
                </div>
            </div>
        </div>
    );
}

export default MiniBox;
