import "./popup.css"

import Swal from "sweetalert2";
const Popup = Swal.mixin({
    icon: "warning",
    confirmButtonText: "OK",
    confirmButtonColor: "#1567d2",
    background: "#0C0C0C",
    color: "#fff",
    customClass: {
        popup: "custom-swal-popup", 
        confirmButton: "custom-swal-button", 
    },
});

export default Popup
