import {
    React,
    useEffect,
    useState,
    useRef,
    useContext,
    createRef,
    forwardRef,
} from "react";
import ProductList from "../../components/ProductList/ProductList";
import Search from "../../components/Search/Search";
import SlotNumber from "../../components/SlotNumber/SlotNumber.js";
import CopyDiv from "../../components/CopyDiv/CopyDiv.js";
import Loader from "../../components/Loader/Loader.js";
import calcPrice from "../../util/calcPrice";

import ReactDatePicker from "react-datepicker"; //Gab- Calendário

import { isMobile } from "react-device-detect";

import Cookies from "js-cookie";
import { TypeAnimation } from "react-type-animation";

import "./Catalog.css";

import ConfettiExplosion from "react-confetti-explosion";

import marketplaces_ from "../../config/marketplaces.json";

import { downloadZip } from "client-zip";
import FileSaver from "file-saver";

import { UserContext } from "../../App.js";
import Einstein from "../../components/Einstein/Einstein.js";

import { createWorker } from "tesseract.js";

import Popup from "../../util/popup.jsx"; //Gab

const productTypes = [
    { id: 0, name: "Relógio", ncm: 91022900 },
    { id: 1, name: "Boné", ncm: 71179000 },
    { id: 2, name: "Cinto", ncm: 71179000 },
    { id: 3, name: "Colar", ncm: 71179000 },
    { id: 4, name: "Brinco", ncm: 71179000 },
    { id: 5, name: "Carteira", ncm: 71179000 },
    { id: 6, name: "Óculos", ncm: 71179000 },
    { id: 7, name: "Pulseira", ncm: 71179000 },
    { id: 8, name: "Calçado", ncm: 71179000 },
    { id: 9, name: "Anel", ncm: 71179000 },
    { id: 10, name: "Lousa", ncm: 96100000 },
];

const getPlpImage = (plpUrl, plpText, isFull) => {
    return new Promise(async (resolve, reject) => {
        if (!plpUrl && !plpText) reject();
        let plpResponse = plpUrl
            ? await fetch(`/uploads/orders/${plpUrl}`).catch(reject)
            : "";
        let plpData = plpUrl ? await plpResponse.text() : plpText;
        if (!plpData) reject();

        console.log(plpData);

        //Gab

        // const isFull = (zplContent) => {
        //     const firstXA = zplContent.indexOf("^XA");
        //     const secondXA = zplContent.indexOf("^XA", firstXA + 1);
        //     return secondXA !== -1; // É FULL se encontrar mais de uma etiqueta
        // };

        // Função para extrair a primeira etiqueta de um arquivo ZPL
        const extractFirstZplLabel = (zplContent) => {
            const startLabel = zplContent.indexOf("^XA");
            const endLabel = zplContent.indexOf("^XZ", startLabel) + 4; // Inclui o tamanho de '^XZ'
            return startLabel !== -1 && endLabel !== -1
                ? zplContent.slice(startLabel, endLabel)
                : null;
        };

        // Verifica se é FULL e extrai a primeira etiqueta se necessário
        if (isFull) {
            console.log(
                "Arquivo ZPL do FULL detectado, extraindo a primeira etiqueta."
            );
            plpData = extractFirstZplLabel(plpData);
        }

        //Fim

        const response = await fetch(
            "https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/",
            {
                method: "POST",
                body: plpData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
        let blob = await response.blob();

        blobToBase64(blob, function (image) {
            resolve(`data:image/png;base64,${image}`);
        });
    });
};

var blobToBase64 = function (blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(",")[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
};

let aistemAddItem;

export default function Catalog(props) {
    const { user, hidden, products } = useContext(UserContext);

    const marketplaces = Object.values(marketplaces_).filter((e) => !e.hidden);

    // const marketplaces = [
    //     { id: 1, name: "Mercado Livre", icon: "ml.svg", maxLength: 60 },
    //     { id: 2, name: "Magalu", icon: "magalu3.svg", maxLength: 120 },
    //     { id: 3, name: "Amazon", icon: "amazon2.svg", maxLength: 60 },
    //     { id: 4, name: "Olist", icon: "olist2.svg", maxLength: 60 },
    //     { id: 5, name: "B2W", icon: "b2w.svg", maxLength: 120 },
    //     { id: 6, name: "Shopee", icon: "shopee.svg", maxLength: 120 },
    //     { id: 0, name: "Outros", icon: "outros5.svg", maxLength: 120 },
    // ];

    const [isExploding, setIsExploding] = useState(false);

    const [busca, setBusca] = useState("");

    const [plp, setPlp] = useState("");
    const [nf, setNf] = useState("");
    const [productLabel, setProductLabel] = useState(""); //estado produto (Gab)
    const [volumeLabel, setVolumeLabel] = useState(""); //estado volume (Gab)
    const [entranceAuth, setEntranceAuth] = useState(""); //estado entrada (Gab)

    const [plpImage, setPlpImage] = useState("");
    const [productLabelImage, setProductLabelImage] = useState(""); //(Gab)
    const [volumeLabelImage, setVolumeLabelImage] = useState(""); //(Gab)
    const [entranceAuthImage, setEntranceAuthImage] = useState(""); //(Gab)

    const [modalPlp, setModalPlp] = useState(false);
    const [modalNfe, setModalNfe] = useState(false);
    const [modalEntranceAuth, setModalEntranceAuth] = useState(false); //(Gab)

    const [modalSoldOut, setModalSoldOut] = useState(false);

    const [hideBar, setHideBar] = useState(false);

    const [plpLoading, setPlpLoading] = useState(false);
    const [nfLoading, setNfLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false); //(Gab)
    const [volumeLoading, setVolumeLoading] = useState(false); //(Gab)
    const [entranceLoading, setEntranceLoading] = useState(false); //(Gab)

    const [orderSuccesfull, setOrderSuccesfull] = useState(false);
    const [marketplaceSelect, setMarketplaceSelect] = useState(false);

    const [marketplace, setMarketplace] = useState(null);

    const [onlySelected, setOnlySelected] = useState(false);

    const [selected, setSelected] = useState([]);
    const [soldOut, setSoldOut] = useState(null);
    const [productsQuery, setProductsQuery] = useState([]);

    const [openBox, setOpenBox] = useState(false);
    const [shaking, setShaking] = useState(false);
    const [openFast, setOpenFast] = useState(false);

    const [modal, setModal] = useState(false);

    const [modalPrice, setModalPrice] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [modalOption, setModalOption] = useState(false);
    const [modalBox, setModalBox] = useState(false);
    const [modalBurn, setModalBurn] = useState(false);

    const [modalObj, setModalObj] = useState({});
    const [modalTitle, setModalTitle] = useState("");

    const [percentual, setPercentual] = useState(false);

    const [percentSave, setPercentSave] = useState(false);

    const [percentMsg, setPercentMsg] = useState("");

    const [imgSelected, setImgSelected] = useState("");

    const [modalProductMarketplace, setModalProductMarketplace] = useState(1);

    const [lucro, setLucro] = useState(40);
    const [tax, setTax] = useState(0);

    const [reloadingTitle, setReloadingTitle] = useState(false);

    const [width, setWidth] = useState(0);

    const [animationClose, setAnimationClose] = useState(false);

    const [boxes, setBoxes] = useState([]);

    const ref = useRef(null);

    const buscaRef = useRef(null);
    // Gab - Calendário
    const [fullDateSelection, setfullDateSelection] = useState(null);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={`select-file checkout select-plp-nfe ${
                fullDateSelection ? "selected" : ""
            }`}
            onClick={onClick}
            ref={ref}
        >
            {fullDateSelection ? (
                value
            ) : (
                <span className="button-select-file full-button ">
                    Data da
                    <br /> coleta
                </span>
            )}
        </div>
    ));

    // Função para desabilitar finais de semana
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 2 && day !== 3; // 0 = Domingo, 6 = Sábado
    };

    // Calcula a data mínima permitida (3 dias a partir de hoje)
    const minSelectableDate = new Date();
    minSelectableDate.setDate(new Date().getDate() + 3);

    // Função para aplicar a classe nos dias desabilitados
    const getDayClassName = (date) => {
        const day = date.getDay();
        if (
            day === 0 ||
            day === 2 ||
            day === 3 ||
            date.getTime() < minSelectableDate.getTime()
        ) {
            return "react-datepicker__day--disabled-custom"; // Classe personalizada para os dias desabilitados
        }
        return "";
    };
    // Fim do Calendário

    useEffect(() => {
        buscaRef.current = busca;
    }, [busca]);

    aistemAddItem = async (id, qtty, removeAll) => {
        let sku = products.find((p) => p.id == id)?.sku;
        if (sku) {
            let timeout = 1000 / sku?.length;
            setBusca("");
            sku.split("").forEach((l, i) => {
                setTimeout(
                    () => setBusca(`${buscaRef.current || ""}${l}`),
                    i * timeout + 300
                );
            });
            setTimeout(() => {
                let newSelected = selected.slice();
                let product =
                    newSelected[newSelected.findIndex((e) => e.id == id)];

                if (product) {
                    let newQtty = Math.min(product.qtde + qtty, 99);
                    if (newQtty > 0) {
                        newSelected[
                            newSelected.findIndex((e) => e.id == id)
                        ].qtde = newQtty;
                        if (product.options.box)
                            product.options.boxQtty =
                                product.options.boxQtty <= 1
                                    ? 1
                                    : product.options.boxQtty - 1;
                    } else {
                        newSelected = selected.filter((e) => e.id != id);
                        setBusca("");
                    }
                } else {
                    newSelected = [
                        {
                            id: id,
                            qtde: Math.min(qtty, 200),
                            options: {},
                            // options: { boxQtty: 1 },
                        },
                        ...selected,
                    ];
                }

                if (removeAll) {
                    newSelected = selected.filter((e) => e.id != id);
                    setBusca("");
                }

                setSelected(newSelected);
            }, 2500);
        }
        // setBusca(products.find((p) => p.id == id)?.sku || "");
        // alert(products.find((p) => p.id == id)?.sku);
    };

    const generateEan = () => {
        let code = "789" + Math.floor(100000000 + Math.random() * 900000000);
        const sum = code
            .split("")
            .map((n, i) => n * (i % 2 ? 3 : 1)) // alternate between multiplying with 3 and 1
            .reduce((sum, n) => sum + n, 0); // sum all values
        const roundedUp = Math.ceil(sum / 10) * 10; // round sum to nearest 10
        return `${code}${roundedUp - sum}`;
    };

    useEffect(() => {
        if (onlySelected) {
            let productsBusca = products.filter((e) => {
                return selected.map((e) => e.id).includes(e.id);
            });
            setProductsQuery(productsBusca);
        } else {
            if (!busca) {
                setProductsQuery(products);
                return;
            }
            let productsBusca = products.filter((e) => {
                return (
                    e.sku
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            busca
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        ) ||
                    e.name
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            busca
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        )
                );
            });
            setProductsQuery(productsBusca);
        }
    }, [onlySelected, selected, products]);

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        var widthTimeout;
        const getwidth = () => {
            clearTimeout(widthTimeout);
            widthTimeout = setTimeout(
                () => setWidth(ref.current.offsetWidth),
                1000
            );
        };
        window.addEventListener("resize", getwidth);
        return () => window.removeEventListener("resize", getwidth);
    }, []);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await fetch("/api/products/getboxes", {
                    method: "GET",
                });
                const data = await response.json();
                setBoxes(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchGroups();
    }, []);

    const fetchTitle = async (tries) => {
        try {
            setReloadingTitle(true);

            const response = await fetch("/api/bling/generatetitle", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    // products: selected,
                    id: modalObj.id,
                    marketplace: modalProductMarketplace,
                }),
            });

            const newName = await response.json();

            setTimeout(() => {
                setModalTitle(newName.title);
                setReloadingTitle(false);
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (modalProduct && Object.values(modalObj).length) fetchTitle();
            setModalObj({ ...modalObj, ean: generateEan() });
        }, 100);
    }, [modalProduct, modalProductMarketplace]);

    useEffect(() => {
        if (selected.length <= 0) {
            setMarketplace(null);
            setOnlySelected(false);
        }
    }, [selected]);

    const validateOrder = async () => {
        if (openBox) return;

        setOpenBox(true);

        let price = selected
            .map((e) => {
                let prod = products.filter((p) => p.id === e.id)[0];
                return (
                    prod.price * (e.qtde || 1) +
                    (e.options.box
                        ? e.options.boxQtty *
                          (boxes.find((e) => e.id == prod.box)?.price || 5)
                        : 0) +
                    (e.options.gift
                        ? e.options.globalGift
                            ? 2
                            : e.qtde * 2
                        : 0)
                );
            })
            .reduce((partialSum, a) => partialSum + a, 0);

        if (price > user.credits) {
            setOpenBox(false);
            return Popup.fire({
                title: "Atenção",
                text: "Saldo insuficiente para realizar essa compra",
            });
        }

        if (marketplace === null) {
            setOpenBox(false);
            return Popup.fire({
                title: "Atenção",
                text: "Selecione o marketplace",
            });
        }

        //Gab mudanças
        if (marketplace === marketplaces_[82]) {
            // Se for marketplace 82 (FULL), validar as etiquetas obrigatórias
            if (!fullDateSelection) {
                setOpenBox(false);
                return Popup.fire({
                    title: "Atenção",
                    text: "Selecione a data de coleta",
                });
            }
            if (!productLabel) {
                setOpenBox(false);
                return Popup.fire({
                    title: "Atenção",
                    text: "Insira a Etiqueta de Produto",
                });
            }
            if (!volumeLabel) {
                setOpenBox(false);
                return Popup.fire({
                    title: "Atenção",
                    text: "Insira a Etiqueta de Volume",
                });
            }
            if (!entranceAuth) {
                setOpenBox(false);
                return Popup.fire({
                    title: "Atenção",
                    text: "Insira a Autorização de Entrada",
                });
            }
        } else {
            // Caso contrário, validar PLP e Nota Fiscal normalmente
            if (!plp) {
                setOpenBox(false);
                return Popup.fire({
                    title: "Atenção",
                    text: "Insira a PLP",
                });
            }
        }

        // if (!plp) {
        //     setOpenBox(false);
        //     return alert("Insira a PLP");
        // }
        if (!nf) {
            setOpenBox(false);
            return Popup.fire({
                title: "Atenção",
                text: "Insira a Nota fiscal",
            });
        }

        //Gab mudanças fim

        setShaking(false);
        setOpenFast(false);
        setTimeout(() => {
            setShaking(true);
            setHideBar(true);
        }, selected.length * 300 + 500);

        try {
            // alert(price);
            // return;
            const response = await fetch("/api/orders/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    products: selected,
                    marketplace: marketplace.id,
                    price: price,
                    status: 0,
                    options: {},
                    plp: plp,
                    nfe: nf,
                    productLabel: productLabel,
                    volumeLabel: volumeLabel,
                    entranceAuth: entranceAuth,
                    fullDateSelection: new Date(fullDateSelection),
                }),
            });

            const data = await response.json();
            if (response.status != 200) {
                setTimeout(() => {
                    setShaking(false);
                    setOpenBox(false);
                    setHideBar(false);
                    setIsExploding(false);
                    setOpenFast(false);
                    setOrderSuccesfull(false);
                }, selected.length * 300 + 1000);
                return Popup.fire({
                    title: "Oops...",
                    text: data.error,
                    icon: "error",
                });
            }
            console.log(data); // Produto cadastrado retornado pelo backend
            setTimeout(() => {
                setPlp("");
                setNf("");
                setMarketplace(null);
                setOpenFast(true);
                setShaking(false);
                setIsExploding(true);

                setTimeout(() => {
                    setHideBar(false);

                    setSelected([]);
                    setTimeout(() => {
                        setOpenBox(false);
                        setIsExploding(false);
                        setOpenFast(false);
                        setOrderSuccesfull(false);
                    }, 2500);

                    setOrderSuccesfull(true);
                }, 500);
            }, selected.length * 300 + 500 + 2000);
        } catch (error) {
            console.error(error);
            setTimeout(() => {
                setShaking(false);
                setHideBar(false);
                setOpenBox(false);
                setIsExploding(false);
                setOpenFast(false);
                setOrderSuccesfull(false);
                Popup.fire({
                    title: "Oops...",
                    text: "Erro ao salvar pedido",
                    icon: "error",
                });
            }, 1000);
        }
    };

    return (
        <>
            <div
                className={`order-modal-bg ${
                    modalNfe || modalEntranceAuth ? "shown" : ""
                }`}
                onClick={() => {
                    setModalNfe(false);
                    setModalEntranceAuth(false);
                }}
            >
                <div
                    className="order-modal order-modal-plp order-modal-nfe"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <iframe
                        src={
                            `${window.location.protocol}//${
                                window.location.hostname == "localhost"
                                    ? `${window.location.hostname}:5000`
                                    : window.location.hostname
                            }/uploads/orders/` +
                            (modalEntranceAuth ? entranceAuth : nf)
                        }
                        type="application/pdf"
                        width="400px"
                        height="550px"
                    />
                </div>
            </div>

            <div
                className={`order-modal-bg ${modalPlp ? "shown" : ""}`}
                onClick={() => {
                    setModalPlp(false);
                    setPlpImage(null);
                }}
            >
                <div
                    className="order-modal order-modal-plp"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <img className="plp-img" src={plpImage} />
                </div>
            </div>

            <div
                className={`modal-product-bg ${modalBox ? "shown" : ""}`}
                onClick={() => {
                    setModalBox(false);
                    console.log(modalObj);
                    setTimeout(() => setModalObj({}), 500);
                    setModalTitle("");
                }}
            >
                <div
                    className="modal-options modal-box"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    Quantas{" "}
                    {modalBox &&
                    products.find((e) => e.id == modalObj.id).type == 1
                        ? "cases"
                        : "caixas"}
                    ?
                    <div className="modal-box-qtty-container">
                        <div className="modal-box-qtty">
                            <span
                                className={`modal-box-plus ${
                                    modalObj.options &&
                                    modalObj.options.boxQtty >= modalObj.qtde
                                        ? "hidden"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (
                                        modalObj.options &&
                                        modalObj.options.boxQtty < modalObj.qtde
                                    ) {
                                        let newSelected =
                                            selected?.slice() || [];
                                        newSelected[
                                            newSelected.findIndex(
                                                (e) => e.id == modalObj.id
                                            )
                                        ].options.boxQtty++;
                                        setSelected(newSelected);
                                    }
                                }}
                            ></span>
                            <SlotNumber
                                className="modal-box-slot"
                                number={
                                    (modalObj.options &&
                                        modalObj.options.boxQtty) ||
                                    1
                                }
                                height={24}
                                duration={400}
                            />
                            <span
                                className={`modal-box-minus ${
                                    modalObj.options &&
                                    modalObj.options.boxQtty < 2
                                        ? "hidden"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (
                                        modalObj.options &&
                                        modalObj.options.boxQtty > 1
                                    ) {
                                        let newSelected =
                                            selected?.slice() || [];
                                        newSelected[
                                            newSelected.findIndex(
                                                (e) => e.id == modalObj.id
                                            )
                                        ].options.boxQtty--;
                                        setSelected(newSelected);
                                    }
                                }}
                            ></span>
                        </div>
                        <div className="modal-box-x">x</div>
                        {modalBox &&
                        products.find((e) => e.id == modalObj.id).type == 1 ? (
                            <img src="/img/case.svg" />
                        ) : (
                            <img src="/img/caixa.svg" />
                        )}
                        <div className="modal-box-value">
                            <small>R$</small>
                            <SlotNumber
                                number={
                                    (modalObj.options &&
                                        modalObj.options.boxQtty *
                                            boxes.find(
                                                (e) =>
                                                    e.id ==
                                                    products.find(
                                                        (e) =>
                                                            e.id == modalObj.id
                                                    ).box
                                            )?.price) ||
                                    5 ||
                                    1
                                }
                                height={24}
                                duration={400}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`modal-product-bg ${modalBurn ? "shown" : ""}`}
                onClick={() => {
                    setModalBurn(false);
                    setTimeout(() => setModalObj({}), 500);
                }}
            >
                <div
                    className="modal-options modal-custom"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    Insira {modalObj.qtde > 1 ? "os nomes" : "o nome"} a gravar:
                    {modalBurn &&
                        Array(modalObj.qtde)
                            .fill("")
                            .map((e, i) => {
                                return (
                                    <div className="modal-custom-input" key={i}>
                                        {i + 1}
                                        <input
                                            maxLength={16}
                                            value={
                                                selected[
                                                    selected.findIndex(
                                                        (e) =>
                                                            e.id == modalObj.id
                                                    )
                                                ]?.options?.names?.[i] || ""
                                            }
                                            onChange={(ev) => {
                                                let newSelected =
                                                    selected?.slice() || [];
                                                if (
                                                    newSelected[
                                                        newSelected.findIndex(
                                                            (e) =>
                                                                e.id ==
                                                                modalObj.id
                                                        )
                                                    ] &&
                                                    !newSelected[
                                                        newSelected.findIndex(
                                                            (e) =>
                                                                e.id ==
                                                                modalObj.id
                                                        )
                                                    ]?.options?.names
                                                ) {
                                                    newSelected[
                                                        newSelected.findIndex(
                                                            (e) =>
                                                                e.id ==
                                                                modalObj.id
                                                        )
                                                    ].options.names = [];
                                                }

                                                newSelected[
                                                    newSelected.findIndex(
                                                        (e) =>
                                                            e.id == modalObj.id
                                                    )
                                                ].options.names[i] =
                                                    ev.target.value.toUpperCase();
                                                setSelected(newSelected);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                </div>
            </div>

            <div
                className={`modal-product-bg ${modalSoldOut ? "shown" : ""}`}
                onClick={() => {
                    setModalSoldOut(false);
                    setSoldOut(null);
                }}
            >
                <div
                    className="modal-options modal-soldout"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div
                        className={`soldout-loading ${soldOut ? "" : "shown"}`}
                    >
                        <Loader />
                    </div>

                    <div
                        className="integration-marketplace-explanation"
                        key={0}
                    >
                        {soldOut ? (
                            <>
                                <Einstein
                                    className="instegrations-einstein"
                                    type="say"
                                />

                                <TypeAnimation
                                    key={1}
                                    className={`integration-saying soldout-saying`}
                                    sequence={[
                                        1500,
                                        `Aqui estão os produtos pausados por mim nos ultimos 7 dias!
A cada 60 dias eu mapeio sua conta, pauso o que não performou e crio novas ofertas!
Essa ação é muito importante para deixar enxutas todas suas lojas nos marketplaces, ajudando no ranqueamento!`,
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 10,
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="soldout-container">
                        {soldOut?.map((p, i) => {
                            return (
                                <div className="soldout-product" key={i}>
                                    <img
                                        src={`/uploads/thumbs/${p.photos[0]}`}
                                    />
                                    <CopyDiv
                                        value={p.sku}
                                        className="soldout-sku"
                                    >
                                        {p.sku}
                                    </CopyDiv>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div
                className={`modal-product-bg ${modalOption ? "shown" : ""}`}
                onClick={() => {
                    setModalOption(false);
                    setTimeout(() => setModalObj({}), 500);
                }}
            >
                <div
                    className="modal-options"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="modal-options-selection">
                        <div
                            className={`modal-options-select ${
                                modalObj.options &&
                                modalObj.options.globalGift == 1
                                    ? "selected"
                                    : ""
                            }`}
                            onClick={() => {
                                if (
                                    !(
                                        modalObj.options &&
                                        modalObj.options.globalGift == 1
                                    )
                                ) {
                                    setAnimationClose(true);
                                    setTimeout(() => {
                                        setAnimationClose(false);
                                    }, 400);
                                }
                                let newSelected = selected?.slice() || [];
                                newSelected[
                                    newSelected.findIndex(
                                        (e) => e.id == modalObj.id
                                    )
                                ].options.globalGift = 1;
                                setSelected(newSelected);
                            }}
                        >
                            Embalagem geral
                        </div>
                        <div
                            className={`modal-options-select ${
                                modalObj.options &&
                                modalObj.options.globalGift == 1
                                    ? ""
                                    : "selected"
                            }`}
                            onClick={() => {
                                if (
                                    modalObj.options &&
                                    modalObj.options.globalGift == 1
                                ) {
                                    setAnimationClose(true);
                                    setTimeout(() => {
                                        setAnimationClose(false);
                                    }, 400);
                                }
                                let newSelected = selected?.slice() || [];
                                newSelected[
                                    newSelected.findIndex(
                                        (e) => e.id == modalObj.id
                                    )
                                ].options.globalGift = 0;
                                setSelected(newSelected);
                            }}
                        >
                            Embalagem individual
                        </div>
                    </div>
                    <div className="gift-modal-row">
                        {modalOption ? (
                            <div className="gift-modal-option">
                                <SlotNumber
                                    number={
                                        modalObj.options &&
                                        modalObj.options.globalGift == 1
                                            ? 1
                                            : modalObj.qtde
                                    }
                                    height={22}
                                    delay={1300}
                                    autoStart={false}
                                />
                                x<img src="/img/presente.svg" />
                                <div className="gift-modal-price">
                                    <small>R$ </small>
                                    <SlotNumber
                                        number={
                                            modalObj.options &&
                                            modalObj.options.globalGift == 1
                                                ? 2
                                                : modalObj.qtde * 2
                                        }
                                        height={25}
                                        delay={1300}
                                        autoStart={false}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div
                            className={`modal-options-gift-animation ${
                                animationClose ? "geralgift" : ""
                            } ${modalOption ? "animation-open" : ""}`}
                        >
                            {[0, 1, 2].map((e, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="modal-options-gift-box"
                                        style={{
                                            transform: !(
                                                modalObj.options &&
                                                modalObj.options.globalGift == 1
                                            )
                                                ? `translateX(${
                                                      (i - 1) * 65
                                                  }px)`
                                                : "",
                                        }}
                                    >
                                        <img
                                            src="/img/catalog/gift-lid2.svg"
                                            className="gift-box-lid"
                                        />
                                        <img
                                            src="/img/catalog/gift-body2.svg"
                                            className="gift-box-body"
                                        />
                                    </div>
                                );
                            })}
                            {[0, 1, 2].map((e, i) => {
                                return (
                                    <img
                                        key={i}
                                        src="/img/catalog/mini-watch.svg"
                                        className="modal-options-gift-product"
                                        style={{
                                            marginLeft: `${
                                                (i - 1) *
                                                (modalObj.options &&
                                                modalObj.options.globalGift == 1
                                                    ? 8
                                                    : 65)
                                            }px`,
                                            zIndex: 10 - i,
                                            transform: `translateX(-50%) rotateZ(${
                                                (i - 1) *
                                                (modalObj.options &&
                                                modalObj.options.globalGift == 1
                                                    ? 12
                                                    : 0)
                                            }deg)`,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`modal-product-bg ${modalProduct ? "shown" : ""}`}
                onClick={() => {
                    setModalProduct(false);
                    setTimeout(() => setModalObj({}), 500);
                }}
            >
                <div
                    className="modal-product"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    {modalProduct ? (
                        <div className="modal-product-section img-section">
                            <div className="modal-product-img">
                                <div className="dummy" />
                                <img src={"/uploads/products/" + imgSelected} />
                            </div>
                            <div className="modal-product-carrousel">
                                {modalObj.photos &&
                                    modalObj.photos?.slice(0, 5).map((e) => {
                                        return (
                                            <img
                                                style={{
                                                    filter:
                                                        imgSelected == e
                                                            ? "none"
                                                            : "brightness(0.3)",
                                                }}
                                                src={"/uploads/products/" + e}
                                                onClick={() =>
                                                    setImgSelected(e)
                                                }
                                            />
                                        );
                                    })}
                            </div>
                            <div
                                className="download-imgs-btn"
                                onClick={() => {
                                    Promise.all(
                                        modalObj.photos.map((photo) =>
                                            fetch(`/uploads/products/${photo}`)
                                        )
                                    ).then(async (res) => {
                                        console.log(res);
                                        const content = await downloadZip(
                                            res.map((e, i) => {
                                                return {
                                                    name: `${i + 1} - ${
                                                        modalObj.sku
                                                    }${
                                                        i === 0
                                                            ? " - PRINCIPAL"
                                                            : ""
                                                    }.${
                                                        e.url.split(".")[
                                                            e.url.split(".")
                                                                .length - 1
                                                        ]
                                                    }`,
                                                    input: e,
                                                };
                                            })
                                        ).blob();
                                        FileSaver.saveAs(
                                            content,
                                            `${modalObj.sku}.zip`
                                        );
                                    });
                                }}
                            >
                                Baixar fotos
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="modal-product-section description-section">
                        <div className="modal-product-box">
                            <div className="modal-product-title-marketplaces">
                                <span>Título automático</span>
                                <img
                                    src="/img/description/ml.svg"
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 1
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(1)
                                    }
                                />
                                <img
                                    src="/img/description/magalu.svg"
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 2
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(2)
                                    }
                                />
                                <img
                                    src="/img/description/amazon3.svg"
                                    style={{
                                        filter: "saturate(0) brightness(0.5)",
                                    }}
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 3
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(3)
                                    }
                                />
                                <img
                                    src="/img/description/olist.svg"
                                    style={{
                                        filter: "saturate(0) brightness(1.8)",
                                    }}
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 4
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(4)
                                    }
                                />
                                <img
                                    src="/img/description/b2w3.svg"
                                    style={{
                                        filter: "saturate(0) brightness(0.7)",
                                    }}
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 5
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(5)
                                    }
                                />
                                <img
                                    src="/img/description/shopee2.svg"
                                    className={`modal-product-title-marketplace ${
                                        modalProductMarketplace == 6
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setModalProductMarketplace(6)
                                    }
                                />
                            </div>
                            <CopyDiv
                                className="modal-title-box"
                                value={modalTitle}
                            >
                                {modalProduct ? (
                                    reloadingTitle ? (
                                        <TypeAnimation
                                            key={1}
                                            sequence={[modalTitle, ""]}
                                            wrapper="span"
                                            preRenderFirstString={true}
                                            deletionSpeed={{
                                                type: "keyStrokeDelayInMs",
                                                value: 2,
                                            }}
                                            // style={{ fontSize: '2em', display: 'inline-block' }}
                                            // repeat={Infinity}
                                        />
                                    ) : (
                                        <TypeAnimation
                                            key={2}
                                            sequence={[200, modalTitle]}
                                            wrapper="span"
                                            speed={{
                                                type: "keyStrokeDelayInMs",
                                                value: 5,
                                            }}
                                            // style={{ fontSize: '2em', display: 'inline-block' }}
                                            // repeat={Infinity}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                                <img
                                    src="/img/reload.svg"
                                    className={`modal-title-box-reload ${
                                        reloadingTitle ? "onerotation" : ""
                                    }`}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        fetchTitle();
                                    }}
                                />
                                <Loader hidden={!reloadingTitle} />
                            </CopyDiv>
                        </div>
                        <div className="modal-product-box description-box">
                            Descrição
                            <CopyDiv
                                className="modal-description-box"
                                value={
                                    modalObj.formatedDescription
                                        ? modalObj.formatedDescription + "\n\n"
                                        : ""
                                }
                            >
                                <div className="modal-description-box-inner">
                                    {modalProduct ? (
                                        <>
                                            <Loader hide="2000" />
                                            <TypeAnimation
                                                sequence={[
                                                    2000,
                                                    modalObj.formatedDescription ||
                                                        "",
                                                ]}
                                                wrapper="span"
                                                speed={{
                                                    type: "keyStrokeDelayInMs",
                                                    value: 1,
                                                }}
                                                // style={{ fontSize: '2em', display: 'inline-block' }}
                                                // repeat={Infinity}
                                            />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </CopyDiv>
                        </div>
                        <div className="product-ean-ncm">
                            <div className="product-ean-container">
                                <div className="ean-name">EAN:</div>
                                <CopyDiv
                                    className="ean-box"
                                    value={modalObj.ean}
                                >
                                    {modalObj.ean}
                                </CopyDiv>
                            </div>
                            <div className="product-ncm-container">
                                <div className="ncm-name">NCM:</div>
                                <CopyDiv
                                    className="ncm-box"
                                    value={productTypes[modalObj.type || 0].ncm}
                                >
                                    {productTypes[modalObj.type || 0].ncm}
                                </CopyDiv>
                            </div>
                        </div>
                        <div className="dimension-box">
                            {/* Dimensões: */}
                            <CopyDiv className="dimensions" value="13">
                                Altura <strong>13cm</strong>
                            </CopyDiv>{" "}
                            <CopyDiv className="dimensions" value="13">
                                Largura <strong>13cm</strong>
                            </CopyDiv>{" "}
                            <CopyDiv className="dimensions" value="16">
                                Comp. <strong>16cm</strong>
                            </CopyDiv>{" "}
                            <CopyDiv className="dimensions" value="100">
                                Peso: <strong>100g</strong>
                            </CopyDiv>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal-prices-bg ${modalPrice ? "shown" : ""}`}
                onClick={() => setModalPrice(false)}
            >
                <div
                    className="modal-prices"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="modal-prices-title">
                        Calculadora de preço de venda - {modalObj?.sku}
                    </div>
                    <div className="modal-prices-container">
                        <div className="modal-prices-section">
                            <label className="modal-price-select">
                                <input
                                    type="radio"
                                    name="modal-price"
                                    value="0"
                                    checked={percentual == false}
                                    onChange={(ev) =>
                                        setPercentual(!ev.target.checked)
                                    }
                                />{" "}
                                <span></span>Preços sugeridos pela Orizom
                            </label>
                            <div
                                className={`orizom-price-info ${
                                    percentual ? "" : "shown"
                                }`}
                            >
                                O cálculo feito pelo nosso sistema não inclui os
                                impostos, apenas os percentuais dos
                                marketplaces, bem como suas respectivas regras
                                de cálculo. Para um cálculo mais preciso, nós
                                sugerimos personalizar seu percentual de lucro
                                na seleção abaixo.
                            </div>
                            <label className="modal-price-select">
                                <input
                                    type="radio"
                                    name="modal-price"
                                    value="1"
                                    checked={percentual == true}
                                    onChange={(ev) =>
                                        setPercentual(ev.target.checked)
                                    }
                                />
                                <span></span>Personalizar percentual de lucro
                            </label>
                            <div
                                className={`percentual-config ${
                                    percentual ? "shown" : ""
                                }`}
                            >
                                Quanto quer lucrar? <span>(%)</span>
                                <br />
                                <div className="percent-config">
                                    <input
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter") {
                                                e.currentTarget.blur();
                                            }
                                        }}
                                        onBlur={() => {
                                            setPercentSave(true);
                                            setTimeout(
                                                () => setPercentSave(false),
                                                2000
                                            );
                                        }}
                                        className={percentMsg ? "red" : ""}
                                        placeholder="%"
                                        value={lucro + "%"}
                                        onChange={(e) => {
                                            let value;
                                            if (!e.target.value.includes("%"))
                                                value = e.target.value?.slice(
                                                    0,
                                                    -1
                                                );
                                            else
                                                value = e.target.value.replace(
                                                    "%",
                                                    ""
                                                );
                                            if (Number(value) > 60)
                                                setLucro(60);
                                            else setLucro(value);

                                            if (Number(value) < 15) {
                                                setPercentMsg(
                                                    <>
                                                        Percentual muito baixo!
                                                        <br />
                                                        Risco de prejuízo em
                                                        devoluções!
                                                    </>
                                                );
                                            } else if (Number(value) > 50) {
                                                setPercentMsg(
                                                    <>
                                                        Percentual muito alto!
                                                        <br />
                                                        Risco de não vender!
                                                    </>
                                                );
                                            } else {
                                                setPercentMsg("");
                                            }
                                        }}
                                    />
                                    <span
                                        className={`percent-msg ${
                                            percentMsg ? "animate" : ""
                                        }`}
                                    >
                                        {percentMsg ? percentMsg : ""}
                                    </span>
                                    <br />
                                </div>
                                Quanto paga de imposto? <span>(%)</span>
                                <br />
                                <input
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            e.currentTarget.blur();
                                        }
                                    }}
                                    onBlur={() => {
                                        setPercentSave(true);
                                        setTimeout(
                                            () => setPercentSave(false),
                                            2000
                                        );
                                    }}
                                    placeholder="%"
                                    value={tax + "%"}
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("%"))
                                            value = e.target.value?.slice(
                                                0,
                                                -1
                                            );
                                        else
                                            value = e.target.value.replace(
                                                "%",
                                                ""
                                            );
                                        if (Number(value) > 40) setTax(40);
                                        else setTax(value);
                                    }}
                                />
                                <div
                                    className={`percent-save ${
                                        percentSave ? "shown" : ""
                                    }`}
                                >
                                    Alterações salvas!
                                </div>
                            </div>
                        </div>
                        <div className="modal-prices-section">
                            <div className="modal-prices-marketplaces">
                                {modalPrice
                                    ? marketplaces?.slice(1, 7).map((e, i) => {
                                          return (
                                              <MarketplaceButton
                                                  i={i}
                                                  marketplace={e}
                                                  lucro={lucro}
                                                  tax={tax}
                                                  percentual={percentual}
                                                  modalObj={modalObj}
                                              />
                                          );
                                      })
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductList
                setModalBurn={setModalBurn}
                setModalBox={setModalBox}
                setModalOption={setModalOption}
                percentual={percentual}
                lucro={lucro}
                tax={tax}
                setImgSelected={setImgSelected}
                setModalObj={setModalObj}
                setModalProduct={setModalProduct}
                setModalPrice={setModalPrice}
                products={productsQuery}
                setSelected={setSelected}
                selected={selected}
                setWidth={props.setWidth}
                setModal={setModal}
                boxes={boxes}
            />
            <Search
                setBusca={setBusca}
                busca={busca}
                orderSuccesfull={orderSuccesfull}
                products={products}
                setProductsQuery={setProductsQuery}
                onChange={() => {
                    setOnlySelected(false);
                }}
                hidden={hidden}
            >
                <div
                    className="show-sold-out"
                    onClick={async () => {
                        setModalSoldOut(true);
                        const response = await fetch("/api/products/soldout", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + Cookies.get("jwt"),
                            },
                        });
                        const data = await response.json();

                        setSoldOut(data);
                    }}
                >
                    Pausados
                </div>
            </Search>
            <div className={`modalbg ${modal ? "visible" : ""}`}></div>
            <div
                className={`box-container align-center ${
                    selected.length > 0 ? "visible" : ""
                } ${hidden ? "hidden" : ""}`}
            >
                <div
                    id="logo"
                    className={`${openBox && !shaking ? "open" : ""} ${
                        shaking ? "shaking" : ""
                    } ${openFast ? "openfast" : ""}`}
                >
                    <div
                        className="box"
                        onClick={() => {
                            validateOrder();
                        }}
                    >
                        <div className="side front">
                            {shaking ? "Pedido" : "Comprar"}
                        </div>
                        <div className="side left">
                            {shaking ? "Fazendo" : ""}
                        </div>
                        <div className="side back"></div>
                        <div className="side right"></div>
                        <div className="side bottom"></div>
                        <div className="flap front"></div>
                        <div className="flap back"></div>
                        <div className="flap left"></div>
                        <div className="flap right"></div>
                    </div>
                </div>
            </div>
            <div
                className={`checkout-bar-btns align-center ${
                    selected.length > 0 ? "visible" : ""
                } ${openBox ? "hidden" : ""} ${hidden ? "hidden2" : ""}`}
            >
                <div
                    className={`mktselect ${!plp && !isMobile ? "hidden" : ""}`}
                >
                    <label className={`select-file checkout`}>
                        <span
                            className={`button-select-file ${
                                marketplace !== null ? "mktselected" : ""
                            }`}
                        >
                            {marketplace !== null ? (
                                marketplace?.parentId ? (
                                    <>
                                        <img
                                            className={
                                                !marketplace?.icon
                                                    ? "selected-parent"
                                                    : ""
                                            }
                                            src={
                                                "/img/marketplaces/full-icon/" +
                                                (marketplace?.icon ||
                                                    marketplaces.find(
                                                        (m) =>
                                                            m.id ===
                                                            marketplace?.parentId
                                                    )?.icon)
                                            }
                                        />
                                        {!marketplace?.icon ? (
                                            <span className="marketplace-child-text">
                                                {marketplace?.name}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <img
                                        src={
                                            "/img/marketplaces/full-icon/" +
                                            marketplace?.icon
                                        }
                                    />
                                )
                            ) : (
                                "PLACE"
                            )}
                        </span>
                    </label>
                    <div
                        className={`marketplace-select-window ${
                            marketplaceSelect ? "open" : ""
                        }`}
                    >
                        {marketplaces
                            .sort((m1, m2) => m2.order - m1.order)
                            .filter((e) => !e.parentId)
                            .map((e) => {
                                return (
                                    <div
                                        key={e.id}
                                        className={`marketplace-btn ${
                                            marketplace?.id === e.id ||
                                            marketplace?.parentId === e.id
                                                ? "selected"
                                                : ""
                                        } ${e.isParent ? "parent" : ""}`}
                                        onClick={() => {
                                            if (e.isParent) return;
                                            setMarketplaceSelect(false);
                                            setMarketplace(e);
                                        }}
                                    >
                                        {e.isParent ? (
                                            marketplaces
                                                .filter(
                                                    (m) => m.parentId === e.id
                                                )
                                                ?.map((mc, i) => {
                                                    return (
                                                        <div
                                                            className="marketplace-child"
                                                            key={mc.id}
                                                            style={{
                                                                bottom: `${
                                                                    i * 35 + 5
                                                                }px`,
                                                            }}
                                                            onClick={() => {
                                                                setMarketplace(
                                                                    mc
                                                                );
                                                                console.log(
                                                                    mc.id
                                                                );
                                                            }}
                                                        >
                                                            {mc.name}
                                                        </div>
                                                    );
                                                })
                                        ) : (
                                            <></>
                                        )}
                                        <img
                                            src={
                                                "/img/marketplaces/full-icon/" +
                                                e.icon
                                            }
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="select-plp-nfe">
                    {/* Ver botão PLP se existir PLP (Gab)*/}
                    {plp && (
                        <div
                            className={`select-file-view ${plp ? "shown" : ""}`}
                            onClick={async () => {
                                setModalPlp(true);
                                // if (!plp) return;
                                setModalPlp(true);
                                setPlpImage(await getPlpImage(plp));
                            }}
                        >
                            Ver
                        </div>
                    )}
                    {/* Botão de Upload da PLP deve aparecer por padrão e sumir apenas se for Full (Gab)*/}
                    {(!marketplace || marketplace !== marketplaces_[82]) && (
                        <label
                            className={`select-file checkout ${
                                plpLoading ? "loading" : ""
                            } ${plp ? "selected" : ""}`}
                        >
                            <div className="loading">
                                <Loader />
                            </div>
                            <span className="button-select-file">PLP</span>
                            <input
                                type="file"
                                name="plp"
                                accept=".txt,.zpl"
                                onChange={async (e) => {
                                    setPlpLoading(true);
                                    setPlp("");
                                    setMarketplace(null);
                                    try {
                                        const formData = new FormData();
                                        formData.append(
                                            "plp",
                                            e.target.files[0]
                                        );
                                        let response = await fetch(
                                            "/api/orders/uploadplp",
                                            {
                                                method: "POST",
                                                body: formData,
                                            }
                                        );

                                        let data = await response.json();

                                        if (isMobile) {
                                            // TIRAR na logica dos outros botoes esse IF
                                            setPlp(data.file.filename);
                                            setPlpLoading(false);
                                            e.target.value = "";
                                            return;
                                        }

                                        const reader = new FileReader();
                                        reader.onload = async (event) => {
                                            let label = event.target.result;
                                            if (
                                                [
                                                    "olist",
                                                    "sender logo",
                                                    "^fdord",
                                                ].some((e) =>
                                                    label
                                                        ?.toLowerCase()
                                                        ?.includes(e)
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[4]
                                                );
                                            } else if (
                                                label.includes("Logo_Meli")
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[83]
                                                );
                                            } else if (
                                                label.includes(
                                                    "Logo Service Correios"
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[85]
                                                );
                                            } else if (
                                                label.includes(
                                                    `"service_type": "drop_off"`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[102]
                                                );
                                            } else if (
                                                label.includes(
                                                    `"service_type": "delivery"`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[101]
                                                );
                                            } else if (
                                                label.includes(
                                                    `^FX Start of a new label-sheet`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[3]
                                                );
                                            } else if (
                                                label.includes(
                                                    `^XA~TA000~JSN^LT0^MNW^PON^PMN`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[5]
                                                );
                                            } else if (
                                                label.includes(
                                                    `ETIQUETA DE VOLUME`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[82]
                                                );
                                                // Esconde o botão de PLP e mostra os outros botões (Gab)
                                                // Esconder o botão de PLP (Gab)
                                                setPlp(null);
                                                setVolumeLabel(
                                                    data.file.filename
                                                );
                                            } else if (
                                                label.includes(
                                                    `^FO22,175^A0N,18,18^FH^FD^FS`
                                                )
                                            ) {
                                                setMarketplace(
                                                    marketplaces_[82]
                                                );
                                                // Esconde o botão de PLP e mostra os outros botões (Gab)
                                                // Esconder o botão de PLP (Gab)
                                                setPlp(null);
                                                setProductLabel(
                                                    data.file.filename
                                                );
                                            } else {
                                                let plpImg = await getPlpImage(
                                                    null,
                                                    label
                                                );
                                                const worker =
                                                    await createWorker(
                                                        "por",
                                                        1
                                                    );
                                                const {
                                                    data: { text },
                                                } = await worker.recognize(
                                                    plpImg
                                                );

                                                console.log(text);
                                                if (
                                                    label.includes(
                                                        `~DGR:DEMO.GRF`
                                                    )
                                                ) {
                                                    if (
                                                        [
                                                            "assinatura:",
                                                            "documento:",
                                                            "recebedor:",
                                                        ].some((e) =>
                                                            text
                                                                ?.toLowerCase()
                                                                ?.includes(e)
                                                        )
                                                    ) {
                                                        setMarketplace(
                                                            marketplaces_[122]
                                                        );
                                                    } else {
                                                        setMarketplace(
                                                            marketplaces_[121]
                                                        );
                                                    }
                                                } else if (
                                                    ["shein"].some((e) =>
                                                        text
                                                            ?.toLowerCase()
                                                            ?.includes(e)
                                                    )
                                                ) {
                                                    setMarketplace(
                                                        marketplaces_[9]
                                                    );
                                                } else if (
                                                    ["olist"].some((e) =>
                                                        text
                                                            ?.toLowerCase()
                                                            ?.includes(e)
                                                    )
                                                ) {
                                                    setMarketplace(
                                                        marketplaces_[4]
                                                    );
                                                } else if (
                                                    [
                                                        "magalu",
                                                        "maagalu",
                                                        "magaalu",
                                                        "magaluu",
                                                    ].some((e) =>
                                                        text
                                                            ?.toLowerCase()
                                                            ?.includes(e)
                                                    ) &&
                                                    ["correios", "sedex"].some(
                                                        (e) =>
                                                            text
                                                                ?.toLowerCase()
                                                                ?.includes(e)
                                                    )
                                                ) {
                                                    setMarketplace(
                                                        marketplaces_[103]
                                                    );
                                                } else if (
                                                    [
                                                        "kan&u",
                                                        "kangu",
                                                        "kanqu",
                                                    ].some((e) =>
                                                        text
                                                            ?.toLowerCase()
                                                            ?.includes(e)
                                                    )
                                                ) {
                                                    setMarketplace(
                                                        marketplaces_[11]
                                                    );
                                                } else if (
                                                    [
                                                        "kwai",
                                                        "Co e",
                                                        "Oo e",
                                                        "/ recebimento",
                                                    ].some((e) =>
                                                        text
                                                            ?.toLowerCase()
                                                            ?.includes(e)
                                                    )
                                                ) {
                                                    setMarketplace(
                                                        marketplaces_[10]
                                                    );
                                                }
                                            }

                                            setPlp(data.file.filename);
                                            setPlpLoading(false);
                                            e.target.value = "";

                                            // const formData = new FormData();
                                            // formData.append("plp", e.target.files[0]);
                                            // await fetch("/api/orders/uploadplp", {
                                            //     method: "POST",
                                            //     headers: {
                                            //         Authorization: "Bearer " + Cookies.get("jwt"),
                                            //     },
                                            //     body: formData,
                                            // })
                                            //     .then((response) => response.json())
                                            //     .then((data) => {
                                            //         setPlp(data.file.filename);
                                            //         setPlpLoading(false);
                                            //         e.target.value = "";
                                            //     })
                                            //     .catch((error) => {
                                            //         console.log(error);
                                            //         alert("Tipo de arquivo inválido");
                                            //         setPlp("");
                                            //         setPlpLoading(false);
                                            //         e.target.value = "";
                                            //     });
                                        };
                                        reader.onerror = (error) =>
                                            console.error(error);
                                        reader.readAsText(e.target.files[0]);
                                    } catch (error) {
                                        console.error(error);
                                        Popup.fire({
                                            title: "Oops...",
                                            text: "Tipo de arquivo inválido",
                                            icon: "error",
                                        });
                                        setPlp("");
                                        setPlpLoading(false);
                                        e.target.value = "";
                                    }
                                }}
                            />
                        </label>
                    )}
                </div>

                {/* Botões para Mercado Livre Full (Gab) */}
                {marketplace === marketplaces_[82] && (
                    <>
                        <div className="select-plp-nfe">
                            <ReactDatePicker
                                selected={fullDateSelection}
                                onChange={(date) => {
                                    setfullDateSelection(date);
                                    // console.log(update[1]);
                                }}
                                locale="pt-BR"
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomInput />}
                                calendarClassName="full-calendar"
                                filterDate={isWeekday} // Aqui desabilita os finais de semana
                                minDate={minSelectableDate} // Define a data mínima como 3 dias a partir de hoje
                                dayClassName={getDayClassName} // Aplica a classe para estilização
                                disabledKeyboardNavigation
                            />
                        </div>

                        <div className="full-select-product-label select-product-label select-plp-nfe">
                            {productLabel && (
                                <div
                                    className={`select-file-view ${
                                        productLabel ? "shown" : ""
                                    }`}
                                    onClick={async () => {
                                        setModalPlp(true);
                                        setPlpImage(
                                            await getPlpImage(
                                                productLabel,
                                                null,
                                                true
                                            )
                                        );
                                    }}
                                >
                                    Ver
                                </div>
                            )}
                            <label
                                className={`select-file checkout ${
                                    productLoading ? "loading" : ""
                                } ${productLabel ? "selected" : ""}`}
                            >
                                <div className="loading">
                                    <Loader />
                                </div>
                                <span className="button-select-file full-button">
                                    Etiquetas
                                    <br /> Produtos
                                </span>
                                <input
                                    type="file"
                                    name="productLabel"
                                    accept=".txt,.zpl"
                                    onChange={async (e) => {
                                        try {
                                            const formData = new FormData();
                                            formData.append(
                                                "plp",
                                                e.target.files[0]
                                            );

                                            let response = await fetch(
                                                "/api/orders/uploadplp",
                                                {
                                                    method: "POST",
                                                    body: formData,
                                                }
                                            );

                                            let data = await response.json();
                                            setProductLabel(data.file.filename);
                                        } catch (error) {
                                            console.error(error);
                                            Popup.fire({
                                                title: "Oops...",
                                                text: "Erro ao enviar Etiqueta de Produto",
                                                icon: "error",
                                            });
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        <div className="full-select-volume-label select-volume-label select-plp-nfe">
                            {volumeLabel && (
                                <div
                                    className={`select-file-view ${
                                        volumeLabel ? "shown" : ""
                                    }`}
                                    onClick={async () => {
                                        setModalPlp(true);
                                        setPlpImage(
                                            await getPlpImage(
                                                volumeLabel,
                                                null,
                                                true
                                            )
                                        ); // Reaproveitando a função para pegar a imagem
                                    }}
                                >
                                    Ver
                                </div>
                            )}
                            <label
                                className={`select-file checkout ${
                                    volumeLoading ? "loading" : ""
                                } ${volumeLabel ? "selected" : ""}`}
                            >
                                <div className="loading">
                                    <Loader />
                                </div>
                                <span className="button-select-file full-button">
                                    Etiquetas
                                    <br /> Volumes
                                </span>
                                <input
                                    type="file"
                                    name="volumeLabel"
                                    accept=".txt,.zpl"
                                    onChange={async (e) => {
                                        try {
                                            const formData = new FormData();
                                            formData.append(
                                                "plp",
                                                e.target.files[0]
                                            );

                                            let response = await fetch(
                                                "/api/orders/uploadplp",
                                                {
                                                    method: "POST",
                                                    body: formData,
                                                }
                                            );

                                            let data = await response.json();
                                            setVolumeLabel(data.file.filename);
                                        } catch (error) {
                                            console.error(error);
                                            Popup.fire({
                                                title: "Oops...",
                                                text: "Erro ao enviar Etiqueta de Volume",
                                                icon: "error",
                                            });
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        <div className="full-select-entrance-auth select-entrance-auth select-plp-nfe">
                            {entranceAuth && (
                                <div
                                    className={`select-file-view ${
                                        entranceAuth ? "shown" : ""
                                    }`}
                                    onClick={async () => {
                                        setModalEntranceAuth(true);
                                    }}
                                >
                                    Ver
                                </div>
                            )}
                            <label
                                className={`select-file checkout ${
                                    entranceLoading ? "loading" : ""
                                }  ${entranceAuth ? "selected" : ""}`}
                            >
                                <div className="loading">
                                    <Loader />
                                </div>
                                <span className="button-select-file full-button">
                                    Autorização
                                    <br /> de Entrada
                                </span>
                                <input
                                    type="file"
                                    name="entranceAuth"
                                    accept=".pdf"
                                    onChange={async (e) => {
                                        try {
                                            const formData = new FormData();
                                            formData.append(
                                                "nf",
                                                e.target.files[0]
                                            );

                                            let response = await fetch(
                                                "/api/orders/uploadnf",
                                                {
                                                    method: "POST",
                                                    body: formData,
                                                }
                                            );

                                            let data = await response.json();
                                            setEntranceAuth(data.file.filename);
                                        } catch (error) {
                                            console.error(error);
                                            Popup.fire({
                                                title: "Oops...",
                                                text: "Erro ao enviar Autorização de Entrada",
                                                icon: "error",
                                            });
                                        }
                                    }}
                                />
                            </label>
                        </div>
                    </>
                )}
                {/* Final dos botões (Gab) */}

                <div className="select-plp-nfe">
                    <div
                        className={`select-file-view ${nf ? "shown" : ""}`}
                        onClick={(ev) => {
                            setModalNfe(true);
                        }}
                    >
                        Ver
                    </div>
                    <label
                        className={`select-file checkout ${
                            nfLoading ? "loading" : ""
                        }  ${nf ? "selected" : ""}`}
                    >
                        <div className="loading">
                            <Loader />
                        </div>
                        <span className="button-select-file">NFe</span>
                        <input
                            type="file"
                            accept=".pdf"
                            onChange={(e) => {
                                setNfLoading(true);
                                setNf("");
                                const formData = new FormData();
                                formData.append("nf", e.target.files[0]);
                                fetch("/api/orders/uploadnf", {
                                    method: "POST",
                                    headers: {
                                        Authorization:
                                            "Bearer " + Cookies.get("jwt"),
                                    },
                                    body: formData,
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        setNf(data.file.filename);
                                        setNfLoading(false);
                                        e.target.value = "";
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        Popup.fire({
                                            title: "Atenção",
                                            text: "Tipo de arquivo inválido",
                                            icon: "error",
                                        });
                                        setNf("");
                                        setNfLoading(false);
                                        e.target.value = "";
                                    });
                            }}
                        />
                    </label>
                </div>
            </div>
            <div className="checkout-bar-products align-center" ref={ref}>
                <div
                    className="checkout-products-container"
                    style={{
                        width: `${selected.length * 50}px`,
                        maxWidth: `${
                            Math.floor(
                                (width -
                                    (marketplace === marketplaces_[82]
                                        ? 550
                                        : 300)) /
                                    50
                            ) * 50
                        }px`,
                    }}
                >
                    {selected
                        .map((e) => {
                            let prods = products.find((el) => el.id == e.id);
                            prods.qtty = e.qtde;
                            prods.options = e.options || {};
                            return prods;
                            //return selected.map(e => e.id).includes(e.id);
                        })
                        .map((e, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        setBusca(e.sku);
                                    }}
                                    style={{
                                        zIndex: `${90000 - i}`,
                                        animationDelay: `${i * 300 + 200}ms`,
                                        transition: `all 1000ms ${
                                            (selected.length - i) * 200
                                        }ms, right ${
                                            openBox
                                                ? `400ms ${i * 200}ms`
                                                : "200ms"
                                        }, top 500ms ${
                                            (selected.length - i) * 200 + 500
                                        }ms`,
                                        right: `${
                                            openBox
                                                ? (width -
                                                      Math.min(
                                                          Math.floor(
                                                              (width -
                                                                  (marketplace ===
                                                                  marketplaces_[82]
                                                                      ? 550
                                                                      : 300)) /
                                                                  50
                                                          ) * 50,
                                                          selected.length * 50
                                                      )) *
                                                      -1 +
                                                  32
                                                : i * 50
                                        }px`,
                                    }}
                                    className={`checkout-bar-product ${
                                        openBox ? "openbox" : "closedbox"
                                    }`}
                                    key={e.id}
                                >
                                    <img
                                        src={
                                            "https://orizom.com.br/uploads/thumbs/" +
                                            e.photos[0]
                                        }
                                    />
                                    {e.qtty > 1 ? (
                                        <div className="qtty">{e.qtty}</div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="mini-options">
                                        {e.options.box ? (
                                            <div className="mini-option">
                                                <img src="/img/caixa.svg" />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {e.options.gift ? (
                                            <div className="mini-option">
                                                <img src="/img/presente.svg" />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="mini-sku">{e.sku}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div
                className={`checkout-bar ${
                    selected.length > 0 && !hideBar ? "visible" : ""
                } ${hidden ? "hidden" : ""}`}
            >
                <div
                    className={`align-center checkout-bar-options ${
                        hideBar ? "hide" : ""
                    }`}
                >
                    {isExploding && (
                        <ConfettiExplosion
                            zIndex={1000000}
                            style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "50px",
                            }}
                            force={2.0}
                            particleSize={8}
                            particleCount={300}
                            duration={4500}
                            // wind={-100}
                        />
                    )}
                    <div className="btns-buy">
                        <div
                            className="btn btn-show-selected"
                            onClick={() => {
                                setOnlySelected(!onlySelected);
                            }}
                        >
                            {!(openBox || shaking)
                                ? !onlySelected
                                    ? `${selected.reduce(
                                          (accum, s) => accum + s.qtde,
                                          0
                                      )} ${
                                          selected.reduce(
                                              (accum, s) => accum + s.qtde,
                                              0
                                          ) > 1
                                              ? "Itens"
                                              : "Item"
                                      }`
                                    : "Ocultar"
                                : ""}
                        </div>
                        {!(openBox || shaking) ? (
                            <div className="total-price">
                                <small>
                                    <strong>-</strong> R$
                                </small>{" "}
                                {selected
                                    .map((e) => {
                                        let prod = products.filter(
                                            (p) => p.id === e.id
                                        )[0];
                                        return (
                                            prod.price * (e.qtde || 1) +
                                            (e.options.box
                                                ? e.options.boxQtty *
                                                  (boxes.find(
                                                      (e) => e.id == prod.box
                                                  )?.price || 5)
                                                : 0) +
                                            (e.options.gift
                                                ? e.options.globalGift
                                                    ? 2
                                                    : e.qtde * 2
                                                : 0)
                                        );
                                    })
                                    .reduce(
                                        (partialSum, a) => partialSum + a,
                                        0
                                    )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

function MarketplaceButton(props) {
    // const [blink, setBlink] = useState(false);

    const price = calcPrice(
        props.marketplace.id,
        props.modalObj.price,
        props.percentual ? props.lucro / 100 : 0.4,
        props.percentual ? props.tax / 100 : 0,
        0
    );
    return (
        <CopyDiv
            style={{ zIndex: 10 - props.i }}
            key={props.marketplace.id}
            className={`modal-price-marketplace`}
            value={`${Math.floor(price) + 0.99}`.replace(".", ",")}
        >
            <img
                src={`/img/marketplaces/full-icon/${props.marketplace.icon}`}
            />
            <div className="modal-price-marketplace-price">
                <small>R$</small>{" "}
                <SlotNumber number={Math.round(price)} height={20} />
            </div>
            {/* <div className="marketplace-explanation">
                100% de custo + 100% de valor de venda = 1 milhão de dólares em
                barras de ouro
            </div> */}
        </CopyDiv>
    );
}

export { aistemAddItem };
