import React, { useEffect, useState, useRef, useContext } from "react";

import RenderIfVisible from "react-render-if-visible";

import "./Spreadsheet.css";
import Loader from "../../components/Loader/Loader.js";
import CopyDiv from "../../components/CopyDiv/CopyDiv.js";

import { TypeAnimation } from "react-type-animation";
import calcPrice from "../../util/calcPrice.js";

import marketplaces_ from "../../config/marketplaces.json";
import Einstein from "../../components/Einstein/Einstein.js";

import { UserContext } from "../../App.js";

const marketplaces = Object.fromEntries(
    Object.entries(marketplaces_).filter((e) => e[1].showBling)
);

const shuffle = (array) => {
    if (!array) return [];
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const headers = [
    { label: "ID", key: "id" },
    { label: "Código", key: "sku" },
    { label: "Descrição", key: "title" },
    { label: "Unidade", key: "un" },
    { label: "NCM", key: "ncm" },
    { label: "Origem", key: "origin" },
    { label: "Preço", key: "price" },
    { label: "Situação", key: "situation" },
    { label: "Estoque", key: "stock" },
    { label: "Peso líquido(Kg)", key: "weight" },
    { label: "Peso bruto(Kg)", key: "weight" },
    { label: "GTIN/EAN", key: "ean" },
    { label: "Largura do produto", key: "width" },
    { label: "Altura do produto", key: "height" },
    { label: "Profundidade do produto", key: "length" },
    { label: "Marca", key: "brand" },
    { label: "Descrição curta", key: "description" },
    { label: "URL Imagens Externas", key: "photos" },
    { label: "Unidade de medida", key: "metricUnit" },
];

export default function Sheet(props) {
    const { user, integrations } = useContext(UserContext);

    const [products, setProducts] = useState([]);
    const [productsQuery, setProductsQuery] = useState([]);

    const [search, setSearch] = useState("");
    const [hideSearch, setHideSearch] = useState(false);

    const [config, setConfig] = useState({});

    const [loading, setLoading] = useState(false);

    const [modalConfig, setModalConfig] = useState(false);
    const [modalExport, setModalExport] = useState(false);

    const [startingSync, setStartingSync] = useState(false);
    const [endingSync, setEndingSync] = useState(false);

    const [integrationsArray, setIntegrationsArray] = useState([]);

    const syncing = useRef(false);

    const fetchProducts = async () => {
        try {
            const response = await fetch("/api/bling/products", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            setTimeout(() => {
                setProducts(data);
                console.log(data);
                setProductsQuery(data);
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // alert(integrations.length);
        setIntegrationsArray(Object.values(integrations));
        if (!Object.values(config).length && Object.values(integrations).length)
            setConfig(
                Object.values(integrations).find((e) => e.type === 0)?.config ||
                    {}
            );
    }, [integrations]);

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (!search) {
            setProductsQuery(products);
        } else {
            let productsSearch = products.filter((e) => {
                return (
                    e.product.sku
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            search
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        ) ||
                    Object.values(e.titles)?.some((t) => {
                        return t
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .includes(
                                search
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                            );
                    })
                );
            });

            setProductsQuery(productsSearch);
        }
    }, [search, products]);

    return (
        <>
            <div
                className={`sheet-export-modal-bg ${
                    modalExport ? "shown" : ""
                }`}
                onClick={() => {
                    setModalExport(false);
                }}
            >
                <div
                    className="sheet-export-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="sheet-config-modal-title">
                        Sincronizar
                        <img
                            src={
                                "/img/marketplaces/full-icon/" +
                                marketplaces[config.marketplace || 0]?.icon
                            }
                        />
                    </div>
                    <div className="sheet-export-modal-explain">
                        Deseja sincronizar todos os produtos agora? O processo
                        pode levar alguns minutos
                    </div>
                    <div className="sheet-export-buttons">
                        <div
                            className="sheet-export-button cancel"
                            onClick={() => setModalExport(false)}
                        >
                            Cancelar
                        </div>
                        <div
                            className="sheet-export-button"
                            onClick={async () => {
                                setStartingSync(true);
                                setModalExport(false);
                                setTimeout(async () => {
                                    await fetch(
                                        "/api/integrations/updateconfig",
                                        {
                                            method: "PUT",
                                            body: JSON.stringify({
                                                id: integrationsArray.find(
                                                    (e) => e.type === 0
                                                ).id,
                                                config: config,
                                            }),
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                            },
                                        }
                                    );

                                    let response = await fetch(
                                        "/api/bling/exportproducts",
                                        {
                                            method: "post",
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                            },
                                        }
                                    );

                                    let integra = await response.json();

                                    setStartingSync(false);
                                    setConfig(integra.config);
                                    syncing.current = true;
                                }, 5000);
                            }}
                        >
                            Sincronizar
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`sheet-config-modal-bg ${
                    modalConfig ? "shown" : ""
                }`}
                onClick={() => {
                    setModalConfig(false);
                }}
            >
                <div
                    className="sheet-config-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="sheet-config-modal-title">
                        Configurações da Planilha
                        <img
                            src={
                                "/img/marketplaces/full-icon/" +
                                marketplaces[config.marketplace || 0]?.icon
                            }
                        />
                    </div>
                    <div className="sheet-config-modal-section">
                        Precificação (por venda)
                        <div className="sheet-config-prices">
                            <div className="sheet-config-percent">
                                Lucro:{" "}
                                <input
                                    value={
                                        (config.pricing &&
                                        config.pricing[config.marketplace || 0]
                                            ? config.pricing[
                                                  config.marketplace || 0
                                              ].profit
                                            : 40) + "%"
                                    }
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("%"))
                                            value = Number(
                                                e.target.value.slice(0, -1)
                                            );
                                        else
                                            value = Number(
                                                e.target.value.replace("%", "")
                                            );
                                        if (Number(value) > 60) value = 60;
                                        setConfig({
                                            ...config,
                                            pricing: {
                                                ...config.pricing,
                                                [config.marketplace || 0]: {
                                                    ...(config.pricing &&
                                                    config.pricing[
                                                        config.marketplace
                                                    ]
                                                        ? config.pricing[
                                                              config.marketplace
                                                          ]
                                                        : {}),
                                                    profit: value,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div className="sheet-config-percent">
                                Imposto:{" "}
                                <input
                                    value={
                                        (config.pricing &&
                                        config.pricing[config.marketplace || 0]
                                            ? config.pricing[
                                                  config.marketplace || 0
                                              ].tax || 0
                                            : 0) + "%"
                                    }
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("%"))
                                            value = Number(
                                                e.target.value.slice(0, -1)
                                            );
                                        else
                                            value = Number(
                                                e.target.value.replace("%", "")
                                            );
                                        if (Number(value) > 60) value = 60;
                                        setConfig({
                                            ...config,
                                            pricing: {
                                                ...config.pricing,
                                                [config.marketplace || 0]: {
                                                    ...(config.pricing &&
                                                    config.pricing[
                                                        config.marketplace || 0
                                                    ]
                                                        ? config.pricing[
                                                              config.marketplace ||
                                                                  0
                                                          ]
                                                        : {}),
                                                    tax: value,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div className="sheet-config-percent">
                                <span>
                                    Valor fixo:
                                    <span className="sheet-percent-info">
                                        ?
                                        <span className="sheet-percent-info-text">
                                            <strong>Campo opcional: </strong>{" "}
                                            você pode inserir custos fixos
                                            (aluguel, contas, etc) divididos
                                            pela projeção de vendas (mês)!
                                            <br />
                                            Ex: R$1000 ÷ 300 vendas ={" "}
                                            <strong>R$3,33</strong>
                                        </span>
                                    </span>
                                </span>
                                <input
                                    value={
                                        "R$ " +
                                        (config.pricing &&
                                        config.pricing[config.marketplace || 0]
                                            ? config.pricing[
                                                  config.marketplace || 0
                                              ].fixed || 0
                                            : 0)
                                    }
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("R$ "))
                                            value = Number(
                                                e.target.value.slice(0, -1)
                                            );
                                        else
                                            value = Number(
                                                e.target.value.replace(
                                                    "R$ ",
                                                    ""
                                                )
                                            );
                                        if (Number(value) > 60) value = 60;
                                        setConfig({
                                            ...config,
                                            pricing: {
                                                ...config.pricing,
                                                [config.marketplace || 0]: {
                                                    ...(config.pricing &&
                                                    config.pricing[
                                                        config.marketplace || 0
                                                    ]
                                                        ? config.pricing[
                                                              config.marketplace ||
                                                                  0
                                                          ]
                                                        : {}),
                                                    fixed: value,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`sheet-products ${
                    integrationsArray.find((e) => e.type === 0)?.syncing
                        ? "syncing"
                        : ""
                }`}
            >
                <Loader hidden={products.length} className="sheet-loading" />
                {/* <div style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(products, ' ', 4)}</div> */}
                {!endingSync &&
                    !startingSync &&
                    productsQuery?.map((e, i) => {
                        return (
                            <SheetProduct
                                user={user}
                                config={config}
                                product={e}
                                index={i}
                                products={products}
                                setProducts={setProducts}
                                key={e.id}
                            />
                        );
                    })}
            </div>
            <div
                className={`sheet-products-syncing ${
                    startingSync ? "shown" : ""
                }`}
            >
                {startingSync ? (
                    <>
                        <Einstein type="say" />
                        <TypeAnimation
                            className="syncing-say"
                            sequence={[
                                1500,
                                "Estou iniciando a sincronização de seus produtos com o bling!",
                            ]}
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 10,
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div
                className={`sheet-products-syncing ${
                    endingSync ? "shown" : ""
                }`}
            >
                {endingSync ? (
                    <>
                        <Einstein type="say" />
                        <TypeAnimation
                            className="syncing-say"
                            sequence={[
                                1500,
                                "Já terminei de sincronizar os seus produtos!",
                            ]}
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 10,
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div
                className={`sheet-products-syncing ${
                    integrationsArray.find((e) => e.type === 0)?.syncing
                        ? "shown"
                        : ""
                }`}
            >
                <Loader />
                <div className="syncing-title">Sincronização em andamento</div>
                <div className="syncing-bar">
                    <div
                        className="syncing-bar-inner"
                        style={{
                            width: `${
                                ((integrationsArray.find((e) => e.type === 0)
                                    ?.syncingData?.qtty || 1) /
                                    (integrationsArray.find((e) => e.type === 0)
                                        ?.syncingData?.total || 100)) *
                                100
                            }%`,
                        }}
                    />
                </div>
                <div className="syncing-qtty">
                    {integrationsArray.find((e) => e.type === 0)?.syncingData
                        ?.qtty || 0}{" "}
                    de{" "}
                    {integrationsArray.find((e) => e.type === 0)?.syncingData
                        ?.total || 0}{" "}
                    produtos
                </div>
            </div>
            <div
                className={`order-search-bar ${
                    products.length &&
                    !integrationsArray.find((e) => e.type === 0)?.syncing
                        ? ""
                        : "hidden"
                }`}
            >
                <div className="order-search">
                    <input
                        placeholder="SKU, nome, ou EAN"
                        onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchParams({'q': e.target.value});
                        }}
                        onFocus={() => setHideSearch(true)}
                    />
                </div>
                {products.length ? (
                    <div
                        className={`order-search-placeholder ${
                            hideSearch ? "hidden" : ""
                        }`}
                    >
                        <TypeAnimation
                            sequence={[
                                1500,
                                "Pesquise por sku, nome do produto, ou EAN",
                            ]}
                            // wrapper="span"
                            speed={{ type: "keyStrokeDelayInMs", value: 25 }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div
                className={`sheet-buttons-container ${
                    products.length &&
                    !integrationsArray.find((e) => e.type === 0)?.syncing
                        ? ""
                        : "hidden"
                }`}
            >
                <div className={`sheet-buttons ${loading ? "loading" : ""}`}>
                    <div className="mktselect sheet-btn">
                        <div className="sheet-marketplace">
                            <img
                                src={
                                    "/img/marketplaces/full-icon/" +
                                    marketplaces[config.marketplace || 0]?.icon
                                }
                            />
                            <img
                                class="sheet-config-icon"
                                src="/img/config-icon.svg"
                                onClick={() => {
                                    setModalConfig(true);
                                }}
                            />
                        </div>
                        <div className={`marketplace-select-window`}>
                            {Object.values(marketplaces).map((e) => {
                                return (
                                    <div
                                        key={e.icon}
                                        className={`marketplace-btn ${
                                            (config?.marketplace || 0) === e.id
                                                ? "selected22"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            setLoading(true);
                                            setTimeout(() => {
                                                setLoading(false);
                                            }, 200);
                                            setConfig({
                                                ...config,
                                                marketplace: e.id,
                                            });
                                            // Cookies.set("marketplace", e.id);

                                            // fetch("/api/bling/changeconfig", {
                                            //     method: "PUT",
                                            //     headers: {
                                            //         "Content-Type":
                                            //             "application/json",
                                            //     },
                                            //     body: JSON.stringify({
                                            //         marketplace: e.id,
                                            //     }),
                                            // });
                                        }}
                                    >
                                        <img
                                            src={
                                                "/img/marketplaces/full-icon/" +
                                                e.icon
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className={`sheet-export-btn sheet-btn ${
                            JSON.stringify(config) !=
                            JSON.stringify(
                                integrationsArray.find((e) => e.type === 0)
                                    ?.config || {}
                            )
                                ? "shown"
                                : ""
                        }`}
                        onClick={() => {
                            console.log(JSON.stringify(config));
                            console.log(
                                JSON.stringify(
                                    integrationsArray.find((e) => e.type === 0)
                                        ?.config
                                )
                            );
                            if (products.length) setModalExport(true);
                        }}
                    >
                        Sincronizar
                    </div>
                </div>
            </div>
        </>
    );
}

function SheetProduct(props) {
    const [name, setName] = useState("");
    const [reloading, setReloading] = useState(false);
    const [newLoaded, setNewLoaded] = useState(false);

    useEffect(() => {
        setName(
            props.product.titles[props.config?.marketplace] ||
                props.product.titles[0] ||
                "Erro ao gerar esse título"
        );
    }, [props.config]);

    const product = props.product.product;

    const pricing = (props.config.pricing || {})[
        props.config?.marketplace || 0
    ];

    const price = calcPrice(
        props?.config?.marketplace || 0,
        product.price,
        pricing ? pricing.profit / 100 || 0 : 0.4,
        pricing ? pricing.tax / 100 || 0 : 0,
        pricing ? pricing.fixed || 0 : 0
    );

    return (
        <RenderIfVisible
            defaultHeight={64}
            visibleOffset={2000}
            rootElementClass={`sheet-product ${
                product.stock < 1 ? "sold-out" : ""
            }`}
            style={{ animationDelay: `${props.index * 300}ms` }}
        >
            <span
                className={`sheet-product-new ${
                    new Date(product.createdAt).getTime() >
                    Date.now() - 1000 * 60 * 60 * 24 * 7
                        ? "shown"
                        : ""
                }`}
            >
                Novo!
            </span>
            <div className="sheet-product-img">
                <img src={"/uploads/thumbs/" + product.photos[0]} />
                <CopyDiv className="sheet-product-sku" value={product.sku}>
                    {product.sku}
                </CopyDiv>
                <img
                    src={"/uploads/thumbs/" + product.photos[0]}
                    className="sheet-product-img-large"
                    style={{
                        top:
                            props.products.length - 1 == props.index
                                ? "-120px"
                                : "-20px",
                    }}
                />
            </div>
            <div
                className={`sheet-product-name-container ${
                    reloading ? "reloading" : ""
                } ${
                    name?.trim().length <
                    marketplaces[props.config.marketplace || 0].maxLength - 10
                        ? "toosmall"
                        : ""
                } ${
                    name?.trim().length <
                    marketplaces[props.config.marketplace || 0].maxLength / 3
                        ? "tootoosmall"
                        : ""
                }`}
            >
                <textarea
                    className="sheet-product-name"
                    maxlength={
                        marketplaces[props.config.marketplace || 0].maxLength
                    }
                    spellcheck="false"
                    value={name}
                    onChange={(ev) => {
                        setName(ev.target.value.replace(/  /gi, " "));
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            ev.preventDefault();
                            return ev.target.blur();
                        }
                    }}
                    onBlur={(ev) => {
                        if (
                            name?.trim().length <
                            marketplaces[props.config.marketplace || 0]
                                .maxLength /
                                3
                        )
                            return;
                        updateTitles(
                            ev.target.value,
                            props.config.marketplace || 0,
                            props.product,
                            props.products,
                            props.setProducts
                        );
                    }}
                />
                <div className="sheet-product-name-length">
                    {name?.trim().length <
                    marketplaces[props.config.marketplace || 0].maxLength /
                        3 ? (
                        <span className="sheet-product-toosmall" key="1">
                            Insira mais caracteres, pois não será salvo!
                        </span>
                    ) : (
                        <span className="sheet-product-toosmall" key="2">
                            Título curto, baixas chances de venda!
                        </span>
                    )}
                    {name?.trim().length}
                </div>
                <div className="name-notsaved">Não salvo!</div>
                <div className="sheet-product-name-overlay">
                    {reloading && !newLoaded ? (
                        <TypeAnimation
                            key={0}
                            sequence={[name, ""]}
                            preRenderFirstString={true}
                            // wrapper="span"
                            deletionSpeed={{
                                type: "keyStrokeDelayInMs",
                                value: 2,
                            }}
                        />
                    ) : newLoaded ? (
                        <TypeAnimation
                            key={1}
                            sequence={[name, 500, () => setReloading(false)]}
                            // wrapper="span"
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 2,
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="sheet-name-reload">
                    <img
                        src="/img/reload.svg"
                        onClick={async () => {
                            setNewLoaded(false);
                            setReloading(true);
                            // let newName = props.product.product.titles
                            //     ? props.product.product.titles[
                            //           Math.floor(
                            //               props.product.product.titles.length *
                            //                   Math.random()
                            //           )
                            //       ]
                            //     : "";
                            // let maxLength =
                            //     marketplaces[props.config.marketplace || 0]
                            //         .maxLength;
                            // newName =
                            //     newName?.slice(0, maxLength) ||
                            //     props.product.product.name ||
                            //     "";

                            // if (newName.length < maxLength - 9) {
                            //     let tags = props.product.product.tags2;

                            //     for (let tag of shuffle(tags)) {
                            //         let nameTag = `${newName} ${tag.text}`;
                            //         if (nameTag.length <= maxLength)
                            //             newName = nameTag;
                            //     }
                            // }

                            const response = await fetch(
                                "/api/bling/generatetitle",
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        // products: selected,
                                        id: props.product.product.id,
                                        marketplace: props.config.marketplace,
                                    }),
                                }
                            );

                            const newName = await response.json();

                            updateTitles(
                                newName.title,
                                props.config.marketplace || 0,
                                props.product,
                                props.products,
                                props.setProducts
                            );
                            setTimeout(() => {
                                setName(newName.title);
                                setTimeout(() => setNewLoaded(true), 500);
                            }, 1000);
                        }}
                    />
                </div>
            </div>
            <CopyDiv value={props.product.ean} className="sheet-product-ean">
                <span>{props.product.ean}</span>
            </CopyDiv>
            {/*{props.product.stock < 1 ? <div className='sheet-sold-out-container'><img className='sheet-sold-out' src='/img/esgotado2.svg'/><span className='sheet-sold-out-text'>Esgotado!</span></div> : ''}*/}
            <CopyDiv
                value={(Math.floor(price) + 0.99).toString().replace(".", ",")}
                className="sheet-product-price"
            >
                {product.stock < 1 ? (
                    <span className="sheet-sold-out-text">Esgotado!</span>
                ) : (
                    ""
                )}
                <small>R$ </small>
                {Math.round(price)}
            </CopyDiv>
        </RenderIfVisible>
    );
}

async function updateTitles(
    title,
    marketplace,
    product,
    products,
    setProducts
) {
    let titles = { ...product.titles, [marketplace]: title };
    if (product.titles[marketplace] == title) return;

    let newProducts = products.slice();
    newProducts[
        products.findIndex((e) => e.idProduct == product.idProduct)
    ].titles = titles;
    setProducts(newProducts);

    try {
        // alert(JSON.stringify(titles));
        const response = await fetch("/api/bling/updatetitles", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                titles: titles,
                product: product.productId,
            }),
        });

        // alert(await response.text());
    } catch (error) {
        console.error(error);
    }
}
