import { useEffect, useState, Fragment, useRef } from "react";
import { useOutletContext } from "react-router-dom";

import Loader from "../../../components/Loader/Loader";

import "./Orders.css";

import marketplaces from "../../../config/marketplaces.json";

import RenderIfVisible from "react-render-if-visible";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function AdminAllOrders() {
    const { adminOrders, adminUsers, adminProducts, adminBoxes } =
        useContext(UserContext);

    const pdfjsLib = window["pdfjs-dist/build/pdf"];
    const ordersRef = useRef("");
    const [plpPrinter, etqPrinter, zebraPrint] = useOutletContext();
    const [modalOrderObj, setModalOrderObj] = useState({});

    const statuses = {
        0: { name: "Aguardando", color: "#ae2f2f" },
        1: { name: "Solicitado", color: "#6200ff" },
        2: { name: "Pronto", color: "#0080ff" },
        3: { name: "A gravar", color: "#ff970f" },
        4: { name: "Gravado", color: "#0080ff" },
        9: { name: "Embalado", color: "rgb(10 119 10)" },
    };

    const statusesOrder = {
        1: { name: "Embalado", color: "rgb(10 119 10)" },
        2: { name: "Cancelado", color: "#c93838" },
    };

    const options = {
        box: { name: "Caixa", img: "" },
        gift: { name: "Presente", img: "" },
    };

    const [orders, setOrders] = useState(null);

    const [search, setSearch] = useState(undefined);
    const [ordersSearch, setOrdersSearch] = useState([]);

    const [onlySelected, setOnlySelected] = useState(false);

    const [cancelModal, setCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [modalOrder, setModalOrder] = useState({});

    const [modalPlp, setModalPlp] = useState(false);
    const [modalNfe, setModalNfe] = useState(false);
    const [plp, setPlp] = useState(false);

    const searchTimeout = useRef(null);

    const hovering = useRef(false);

    const getPlpImage = (plpUrl) => {
        return new Promise(async (resolve, reject) => {
            if (!plpUrl) reject();

            const plpResponse = await fetch(`/uploads/orders/${plpUrl}`);
            let plpData = await plpResponse.text();
            if (!plpData) reject();

            const response = await fetch(
                "https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/",
                {
                    method: "POST",
                    body: plpData,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            let blob = await response.blob();

            blobToBase64(blob, function (image) {
                resolve(`data:image/png;base64,${image}`);
            });
        });
    };

    var blobToBase64 = function (blob, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(",")[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    };

    useEffect(() => {
        searchTimeout.current = setTimeout(updateSearch, 300);
        return () => clearTimeout(searchTimeout.current);
    }, [orders, search, onlySelected]);

    const updateSearch = async () => {
        if (!orders) return;
        if (search || onlySelected) {
            let newOrders = Object.values(orders);

            newOrders = newOrders.filter((e) => {
                let src = search.toLowerCase();
                return (
                    src.includes(e.id) ||
                    e.id.toString().padStart(6, 0).includes(src) ||
                    Object.values(e.order_products).some((en) =>
                        adminProducts
                            .find((p) => en.productId === p.id)
                            .sku.toLowerCase()
                            .includes(src)
                    ) ||
                    Object.values(e.order_products).some(
                        (en) =>
                            src.includes(
                                adminProducts
                                    .find((p) => en.productId === p.id)
                                    .sku.toLowerCase()
                            ) ||
                            marketplaces[e.marketplace]?.name
                                .toLowerCase()
                                .includes(src) ||
                            adminUsers
                                .find((p) => e.userId === p.id)
                                ?.email?.toLowerCase()
                                ?.includes(src)
                    )
                );
            });
            setOrdersSearch(newOrders);
        } else {
            setOrdersSearch(Object.values(orders));
        }
    };

    useEffect(() => {
        ordersRef.current = orders || [];
    }, [orders]);

    useEffect(() => {
        setOrders(adminOrders.filter((order) => [1, 2].includes(order.status)));
    }, [adminOrders]);

    function getEtq(id, sku, name) {
        return `^XA
            ^PW319
            ^FWN
            ^LH0,5
            ^FO245,120^GFA,170,170,5,,::003C0018,007E007E,00FF00FE,00FF00FF,:00FE00FE,007E007E,003C003C,,I07FFE,003JFE,00LF8,03FF80FFE,07F8001FF,0FEJ07F81FCJ03F81F8J01FC1F8K0FC:1F8J01FC1FCJ03F80FEJ07F807F8I0FF,03FF00FFE,00LF8,003JFE,I07IF,,:::^FS
            
            ^BY2
            ^FO35,8 ^BCN,100,N ^FD${`${id}`.padStart(8, "0")}^FS
            ^FO35,125 ^A0,30 ^FD${sku}^FS
            ^FO35,165 ^A0,20 ^FD${(name || "Produto")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}^FS
            ^XZ`;
    }

    function printPLPs(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                let plps = [];

                for await (let o of orders) {
                    try {
                        const plpResponse = await fetch(
                            `/uploads/orders/${o.plp}`
                        );
                        let plpData = await plpResponse.text();
                        if (!plpData) continue;

                        let skus = o.order_products
                            .map(
                                (e) =>
                                    adminProducts.find(
                                        (p) => p.id === e.productId
                                    )?.sku
                            )
                            .join(", ");

                        let plp = plpData;

                        plp = plp.replace("^XA^MCY^XZ", "ZPLAMAZONZPL");
                        plp = plp.replace(
                            /\^XA/i,
                            `^XA ^PW1200 ^BY0,0,0 ^FWN
            ^FO760,18^GFA,104,104,4,,:01C008,03E01E,03F03F,::01E01E,,003FF,01IFE,07JF8,0FC00FE,1FI03F,3EI01F,3EJ0F,3EJ0F83EI01F,1FI03F,1FC007E,07FC7FC,01JF,003FF8,,::^FS
            ^FO25,20^BY2^BCN,23,N,N,N^FD ${o.id} ^FS
            ^FO260,25^A0N,20,20^FD Cod: ${`${o.id}`.padStart(8, 0)} ^FS
            ^FO400,25^A0N,20,20^FD SKU: ${
                skus.length > 27 ? "Diversos" : skus
            } ^FS ^LH,27`
                        );
                        plp = plp.replace(/\^LH\d+,\d+/i, "$0 ^LH,32");
                        plp = plp.replace(/\^XZ/i, "^LH0,0 ^XZ");
                        plp = plp.replace("ZPLAMAZONZPL", "^XA^MCY^XZ");

                        plps.push(plp);
                    } catch {}
                }

                zebraPrint(plpPrinter, plps.join("\n"));
                resolve("ok");
                return;
            } catch (err) {
                reject(err);
                return;
            }
        });
    }

    function printNFes(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                orders.forEach((e) => {
                    let newOrders = { ...ordersRef.current };
                    if (!newOrders[e.id]) return;
                    newOrders[e.id].nfePrinted = true;
                    setOrders(newOrders);
                });

                for await (let order of orders) {
                    const response = await fetch(
                        `/uploads/orders/${order.nfe}`
                    );
                    const blob = await response.blob();
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        const data = atob(
                            e.target.result.replace(/.*base64,/, "")
                        );
                        renderPage(data, order.id)
                            .then(() => {
                                if (order.id === orders[orders.length - 1].id)
                                    resolve("ok");
                            })
                            .catch((err) =>
                                alert(
                                    `Falha ao imprimir pedido ${order.id}. Verifique a NF.`
                                )
                            );
                    };
                    reader.readAsDataURL(blob);
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    function renderPage(data, orderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const pdf = await pdfjsLib.getDocument({ data }).promise;
                let page = await pdf.getPage(1);
                const canvas = document.createElement("canvas");
                canvas.setAttribute("className", "canv");
                let viewport = page.getViewport({ scale: 2 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const ctx = canvas.getContext("2d");
                let render_context = {
                    canvasContext: ctx,
                    viewport: viewport,
                };
                await page.render(render_context).promise;
                ctx.font = "bold 30px Montserrat";
                ctx.fillText(
                    `${orderId}`.padStart(8, 0),
                    40,
                    viewport.height - 10
                );
                canvas.toBlob((blob) => {
                    zebraPrint(plpPrinter, blob, "img");
                    canvas.remove();
                    resolve("ok");
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    const selectedQtde =
        orders && Object.values(orders).filter((e) => e.checked).length;

    return (
        <>
            <div
                className={`order-modal-admin-bg-2 ${modalNfe ? "shown" : ""}`}
                onClick={() => setModalNfe(false)}
            >
                <div
                    className="order-modal-admin order-modal-plp order-modal-nfe"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <iframe
                        src={
                            `${window.location.protocol}//${
                                window.location.hostname == "localhost"
                                    ? `${window.location.hostname}:5000`
                                    : window.location.hostname
                            }/uploads/orders/` + modalOrderObj.nfe
                        }
                        type="application/pdf"
                        width="400px"
                        height="550px"
                    />
                </div>
            </div>

            <div
                className={`order-modal-admin-bg-2 ${modalPlp ? "shown" : ""}`}
                onClick={() => {
                    setModalPlp(false);
                    setPlp(null);
                }}
            >
                <div
                    className="order-modal-admin order-modal-plp"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <img className="plp-img" src={plp} />
                </div>
            </div>
            <div
                className={`admin-cancel-modal-bg ${
                    cancelModal ? "shown" : ""
                }`}
                onClick={() => setCancelModal(false)}
            >
                <div
                    className="admin-cancel-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    Qual é o motivo do cancelamento?
                    <textarea
                        onChange={(ev) => setCancelReason(ev.target.value)}
                        value={cancelReason}
                    />
                    <div
                        className="admin-cancel-modal-btn"
                        onClick={() => {
                            if (!cancelReason)
                                return alert("Preencha o motivo");

                            fetch("/api/orders/cancel", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    orderId: modalOrder.id,
                                    reason: cancelReason,
                                }),
                            });

                            setCancelReason("");
                            setCancelModal(false);
                        }}
                    >
                        Confirmar cancelamento
                    </div>
                </div>
            </div>
            <div className="admin-order-container-wrapper">
                <div className="admin-order-container">
                    <Loader className={`${orders ? "hidden" : ""}`} />
                    <div
                        className={`admin-order-search ${
                            hovering.current ? "hovering" : ""
                        }`}
                    >
                        <div className="admin-order-search-container">
                            <div className="admin-order-search-input">
                                <input
                                    placeholder="Pesquise por nº do pedido, SKU ou marketplace"
                                    value={search}
                                    onChange={(ev) => {
                                        setSearch(ev.target.value);
                                        setOnlySelected(false);
                                    }}
                                />
                                <div className="admin-search-qtty">
                                    <div>
                                        {Object.keys(ordersSearch).length}{" "}
                                        Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {Object.keys(ordersSearch).length !== 0
                        ? ordersSearch.slice(0, 1000).map((order, i) => {
                              if (!order) return <></>;

                              let clickTimeout;

                              order.user = adminUsers.find(
                                  (u) => u.id === order.userId
                              );

                              //   order.product;

                              return (
                                  <RenderIfVisible
                                      defaultHeight={126}
                                      visibleOffset={2000}
                                      rootElementClass={`admin-order admin-order-static`}
                                      key={order.id}
                                  >
                                      <div
                                          className={`admin-order admin-order-static`}
                                          key={order.id}
                                      >
                                          <div className="admin-order-dates">
                                              <div className="admin-order-date">
                                                  Confirmado
                                                  <div className="admin-order-date-number">
                                                      {new Date(
                                                          order?.statuses
                                                              ?.confirmed ||
                                                              order?.createdAt
                                                      )
                                                          .toLocaleString(
                                                              "pt-BR"
                                                          )
                                                          .slice(0, -3)
                                                          .replace(",", "")}
                                                  </div>
                                              </div>
                                              {order?.statuses?.packed ? (
                                                  <div className="admin-order-date packed">
                                                      Embalado
                                                      <div className="admin-order-date-number">
                                                          {new Date(
                                                              order?.statuses
                                                                  ?.packed ||
                                                                  Date.now()
                                                          )
                                                              .toLocaleString(
                                                                  "pt-BR"
                                                              )
                                                              .slice(0, -3)
                                                              .replace(",", "")}
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <></>
                                              )}
                                              {order?.statuses?.collected ? (
                                                  <div className="admin-order-date collected">
                                                      Coletado
                                                      <div className="admin-order-date-number">
                                                          {new Date(
                                                              order?.statuses
                                                                  ?.collected ||
                                                                  Date.now()
                                                          )
                                                              .toLocaleString(
                                                                  "pt-BR"
                                                              )
                                                              .slice(0, -3)
                                                              .replace(",", "")}
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <></>
                                              )}
                                              {order?.statuses?.cancelled ? (
                                                  <div className="admin-order-date cancelled">
                                                      Cancelado
                                                      <div className="admin-order-date-number">
                                                          {new Date(
                                                              order?.statuses
                                                                  ?.cancelled ||
                                                                  Date.now()
                                                          )
                                                              .toLocaleString(
                                                                  "pt-BR"
                                                              )
                                                              .slice(0, -3)
                                                              .replace(",", "")}
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <></>
                                              )}
                                          </div>
                                          <div className="admin-order-menu">
                                              <div
                                                  className="admin-order-number"
                                                  style={{
                                                      backgroundColor:
                                                          statusesOrder[
                                                              order.status
                                                          ].color,
                                                      userSelect: "text",
                                                  }}
                                              >
                                                  {`${order.id}`.padStart(6, 0)}
                                              </div>
                                              <div className="admin-order-btns">
                                                  <div
                                                      className={
                                                          order.status === 1
                                                              ? "printed"
                                                              : ""
                                                      }
                                                      onClick={async () => {
                                                          clearTimeout(
                                                              clickTimeout
                                                          );
                                                          clickTimeout =
                                                              setTimeout(() => {
                                                                  if (
                                                                      !order.plp
                                                                  )
                                                                      return alert(
                                                                          "PLP não encontrada"
                                                                      );
                                                                  setModalPlp(
                                                                      true
                                                                  );

                                                                  getPlpImage(
                                                                      order.plp
                                                                  ).then(
                                                                      (img) =>
                                                                          setPlp(
                                                                              img
                                                                          )
                                                                  );
                                                              }, 200);
                                                      }}
                                                      onDoubleClick={() => {
                                                          clearTimeout(
                                                              clickTimeout
                                                          );
                                                          printPLPs([order]);
                                                      }}
                                                  >
                                                      PLP
                                                  </div>
                                                  <div
                                                      className={
                                                          order.status === 1
                                                              ? "printed"
                                                              : ""
                                                      }
                                                      onDoubleClick={() => {
                                                          clearTimeout(
                                                              clickTimeout
                                                          );
                                                          printNFes([order]);
                                                      }}
                                                      onClick={() => {
                                                          clearTimeout(
                                                              clickTimeout
                                                          );
                                                          clickTimeout =
                                                              setTimeout(() => {
                                                                  if (
                                                                      !order.nfe
                                                                  )
                                                                      return;
                                                                  setModalOrderObj(
                                                                      order
                                                                  );
                                                                  setModalNfe(
                                                                      true
                                                                  );
                                                              }, 200);
                                                      }}
                                                  >
                                                      NFe
                                                  </div>
                                                  {order.status === 2 ? (
                                                      <div
                                                          className={`admin-cancel-order canceled`}
                                                      >
                                                          <div className="admin-cancel-reason-show">
                                                              {order?.options?.cancelReason?.replace(
                                                                  "por você",
                                                                  "pelo franqueado"
                                                              )}
                                                          </div>
                                                          Cancelado
                                                      </div>
                                                  ) : (
                                                      <div
                                                          className={`admin-cancel-order`}
                                                          onClick={() => {
                                                              setModalOrder(
                                                                  order
                                                              );
                                                              setCancelModal(
                                                                  true
                                                              );
                                                          }}
                                                      >
                                                          Cancelar
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="admin-order-price">
                                                  <small>R$</small>{" "}
                                                  {order.price}
                                              </div>
                                              <div className="admin-order-marketplace-img">
                                                  {" "}
                                                  <img
                                                      src={
                                                          marketplaces[
                                                              order.marketplace
                                                          ]?.img ||
                                                          marketplaces[
                                                              marketplaces[
                                                                  order
                                                                      .marketplace
                                                              ]?.parentId
                                                          ]?.img
                                                      }
                                                      alt={
                                                          marketplaces[
                                                              order.marketplace
                                                          ]?.name
                                                      }
                                                  />
                                                  {marketplaces[
                                                      order.marketplace
                                                  ]?.parentId &&
                                                  !marketplaces[
                                                      order.marketplace
                                                  ]?.img ? (
                                                      <span className="admin-marketplace-sub">
                                                          {
                                                              marketplaces[
                                                                  order
                                                                      .marketplace
                                                              ]?.name
                                                          }
                                                      </span>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                              {order.marketplace !== 82 ? (
                                                  order.idBling ? (
                                                      <img
                                                          className="order-bling-icon"
                                                          src="/img/orders/bling.svg"
                                                      />
                                                  ) : order.meliId ? (
                                                      <div className="order-bling-icon">
                                                          AI-stem
                                                      </div>
                                                  ) : (
                                                      <div className="order-bling-icon manual">
                                                          Manual
                                                      </div>
                                                  )
                                              ) : (
                                                  <></>
                                              )}
                                              {order.autoPrint ? (
                                                  <img
                                                      className="order-starbox-logo"
                                                      src="/img/starbox.png"
                                                  />
                                              ) : (
                                                  <></>
                                              )}
                                              <a
                                                  className="admin-order-username"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  href={`https://wa.me/55${order?.user?.phone}`}
                                              >
                                                  {order?.user?.name
                                                      .split(" ")
                                                      .slice(0, 2)
                                                      .join(" ")}
                                              </a>
                                              {/* <div>{new Date(order.createdAt).toLocaleString("br")}</div> */}
                                          </div>
                                          <div className="admin-order-products">
                                              {Object.keys(
                                                  order.order_products
                                              ).map((productKey) => {
                                                  const orderProduct =
                                                      order.order_products[
                                                          productKey
                                                      ];
                                                  orderProduct.product =
                                                      adminProducts.find(
                                                          (p) =>
                                                              orderProduct.productId ===
                                                              p.id
                                                      );
                                                  let clickTimeout;
                                                  return (
                                                      <Fragment
                                                          key={orderProduct.id}
                                                      >
                                                          <div
                                                              className={`admin-order-product`}
                                                              style={{
                                                                  backgroundColor:
                                                                      statusesOrder[
                                                                          order
                                                                              .status
                                                                      ]?.color,
                                                                  borderColor:
                                                                      statusesOrder[
                                                                          order
                                                                              .status
                                                                      ]?.color,
                                                              }}
                                                              onDoubleClick={(
                                                                  ev
                                                              ) => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  zebraPrint(
                                                                      etqPrinter,
                                                                      getEtq(
                                                                          orderProduct
                                                                              .product
                                                                              .id,
                                                                          orderProduct
                                                                              .product
                                                                              .sku,
                                                                          orderProduct
                                                                              .product
                                                                              .name
                                                                      )
                                                                  );
                                                              }}
                                                              onMouseEnter={() => {
                                                                  hovering.current = true;
                                                              }}
                                                              onMouseLeave={() => {
                                                                  hovering.current = false;
                                                              }}
                                                              key={
                                                                  orderProduct.id
                                                              }
                                                          >
                                                              <img
                                                                  src={`/uploads/thumbs/${orderProduct.product.photos[0]}`}
                                                                  alt={
                                                                      orderProduct.sku
                                                                  }
                                                              />
                                                              <div className="admin-order-sku">
                                                                  {
                                                                      orderProduct
                                                                          .product
                                                                          .sku
                                                                  }
                                                              </div>
                                                              <img
                                                                  className="expand"
                                                                  src={`/uploads/thumbs/${orderProduct.product.photos[0]}`}
                                                                  alt={
                                                                      orderProduct.sku
                                                                  }
                                                              />
                                                          </div>
                                                          {(Array.isArray(
                                                              orderProduct.options
                                                          )
                                                              ? orderProduct.options
                                                              : []
                                                          ).map((op, index) => {
                                                              let option =
                                                                  options[
                                                                      op.name
                                                                  ];
                                                              let name, image;

                                                              switch (op.name) {
                                                                  case "box":
                                                                      let box =
                                                                          adminBoxes.find(
                                                                              (
                                                                                  b
                                                                              ) =>
                                                                                  b.id ===
                                                                                  orderProduct
                                                                                      .product
                                                                                      .box
                                                                          );
                                                                      if (box) {
                                                                          name =
                                                                              box.name ||
                                                                              "Caixa";
                                                                          image = `/uploads/internal/${box.photo}`;
                                                                      }
                                                                      break;
                                                                  case "gift":
                                                                      name =
                                                                          "Presente";
                                                                      image =
                                                                          "/img/presente3.png";
                                                                      break;
                                                                  case "name":
                                                                      name =
                                                                          op.value;
                                                                      image =
                                                                          "/img/burn.png";
                                                                      break;
                                                                  default:
                                                                      name =
                                                                          op.name;
                                                                      break;
                                                              }
                                                              return (
                                                                  <div
                                                                      className="admin-order-product"
                                                                      style={{
                                                                          backgroundColor:
                                                                              statuses[
                                                                                  op
                                                                                      .status
                                                                              ]
                                                                                  .color,
                                                                      }}
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      {image ? (
                                                                          <>
                                                                              <img
                                                                                  src={
                                                                                      image
                                                                                  }
                                                                                  alt={
                                                                                      name
                                                                                  }
                                                                              />
                                                                              <img
                                                                                  className="expand"
                                                                                  src={
                                                                                      image
                                                                                  }
                                                                                  alt={
                                                                                      name
                                                                                  }
                                                                              />
                                                                          </>
                                                                      ) : (
                                                                          ""
                                                                      )}
                                                                      <div className="admin-order-sku">
                                                                          {name}
                                                                      </div>
                                                                  </div>
                                                              );
                                                          })}
                                                      </Fragment>
                                                  );
                                              })}
                                          </div>
                                      </div>
                                  </RenderIfVisible>
                              );
                          })
                        : ""}
                </div>
            </div>
        </>
    );
}
