import React, { useContext, useEffect, useState } from "react";
import "./Starbox.css";
import {
    FaArrowDown,
    FaArrowLeft,
    FaArrowRight,
    FaArrowUp,
} from "react-icons/fa";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import MiniBox from "../../../components/MiniBox/MiniBox";

import { WithContext as ReactTags } from "react-tag-input";

import { UserContext } from "../../../App.js";
import Loader from "../../../components/Loader/Loader.js";

const Starbox = () => {
    const {
        starboxSlots,
        adminProducts,
        starboxTasks,
        adminOrders,
        starboxStatus,
        setStarboxStatus,
    } = useContext(UserContext);

    const [productPopup, setProductPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [currentShelfIndex, setCurrentShelfIndex] = useState(0); // Para controle da prateleira atual
    const [shelves, setShelves] = useState(null);

    const [newProducts, setNewProducts] = useState(false);

    const [positionChanged, setPositionChanged] = useState(false);

    useEffect(() => {
        if (showPopup) setPositionChanged(false);
    }, [showPopup]);

    const getRobotStatusColor = (robotStatus) => {
        switch (robotStatus) {
            case "Offline":
                return "status-offline";
            case "Online":
                return "status-online";
            case "Operando":
                return "status-operating";
            case "Falha":
                return "status-error";
            default:
                return "";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return "#f0ad4e"; // Agendado (amarelo)
            case 1:
                return "#5bc0de"; // Em andamento (azul)
            case 2:
                return "#5cb85c"; // Concluído (verde)
            case 3:
                return "#d9534f"; // Cancelado (vermelho)
            default:
                return "#ffffff";
        }
    };

    const renderTask = (task, index) => (
        <div
            key={task?.id}
            className="task-list-item"
            style={{ borderColor: getStatusColor(task.status) }}
            data-status={task.status}
        >
            {task.text}
            <IoMdCloseCircle
                className="cancel-icon"
                // onClick={() => handleCancelTask(index)}
            />
        </div>
    );

    const rows = 8;
    const columns = 10;
    const totalShelves = 2; // Número de prateleiras
    // const shelves = Array(totalShelves)
    //     .fill(null)
    //     .map(() => Array(rows * columns).fill(null)); // Array para várias prateleiras

    useEffect(() => {
        var collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
        });
        let tempShelves = Object.groupBy(starboxSlots, ({ shelf }) => shelf);
        console.log(tempShelves);
        tempShelves = Object.values(tempShelves).map((sh) =>
            sh.sort((a, b) => collator.compare(a.label, b.label))
        );
        setShelves(tempShelves);
    }, [starboxSlots]);

    useEffect(() => {
        const newOrders = adminOrders?.filter(
            (order) =>
                new Date(order.createdAt).getTime() >
                Date.now() - 10 * 24 * 60 * 60 * 1000
        );
        let newProd = adminProducts.map((e) => {
            return {
                ...e,
                uniqueOrders: newOrders.reduce(
                    (prev, curr) =>
                        prev +
                        (curr.order_products.length === 1 &&
                        curr.order_products[0].productId === e.id
                            ? 1
                            : 0),
                    0
                ),
            };
        });
        setNewProducts(newProd);
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            if (Date.now() - starboxStatus.lastUpdate > 3 * 60 * 1000)
                setStarboxStatus((status) => {
                    return { ...status, status: "Offline" };
                });
        }, 1000);

        return () => clearInterval(id);
    }, [starboxStatus]);

    const getLabel = (index) => {
        const row = Math.floor(index / columns);
        const col = index % columns;
        const rowLabel = String.fromCharCode(65 + row);
        const colLabel = col + 1;
        return `${rowLabel}${colLabel}`;
    };

    const moveRobot = (direction, qtty) => {
        setStarboxStatus((status) => {
            return { ...status, status: "Operando" };
        });
        fetch("/api/starbox/createtask", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                body: { [direction]: qtty },
                text: `Mover ${direction} para ${qtty > 0 ? "+" : ""}${qtty}`,
                type: 2,
            }),
        });

        setPosition((prevPosition) => {
            switch (direction) {
                case "y":
                    return { ...prevPosition, y: prevPosition.y + qtty };
                case "x":
                    return { ...prevPosition, x: prevPosition.x + qtty };
                default:
                    return prevPosition;
            }
        });
    };

    const [isExtended, setIsExtended] = useState(false);

    const handleAdvance = () => {
        setStarboxStatus((status) => {
            return { ...status, status: "Operando" };
        });
        fetch("/api/starbox/createtask", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                body: { z: 2000 },
                text: `Avançar garra`,
                type: 4,
            }),
        });
    };

    const handleRetract = () => {
        setStarboxStatus((status) => {
            return { ...status, status: "Operando" };
        });
        fetch("/api/starbox/createtask", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                body: { z: 0 },
                text: `Recuar garra`,
                type: 4,
            }),
        });
    };

    const goToNextShelf = () => {
        if (currentShelfIndex < totalShelves - 1) {
            setCurrentShelfIndex(currentShelfIndex + 1);
        }
    };

    const goToPreviousShelf = () => {
        if (currentShelfIndex > 0) {
            setCurrentShelfIndex(currentShelfIndex - 1);
        }
    };

    return (
        <>
            <div className="shelf-page">
                <div className="shelf-column">
                    <div className="shelf-navigation">
                        <FaArrowLeftLong
                            className="button-navigation"
                            onClick={goToPreviousShelf}
                            disabled={currentShelfIndex === 0}
                        />
                        <span>Prateleira {currentShelfIndex + 1}</span>
                        <FaArrowRightLong
                            className="button-navigation"
                            onClick={goToNextShelf}
                            disabled={currentShelfIndex === totalShelves - 1}
                        />
                    </div>

                    <div
                        className="grid-container"
                        style={{
                            gridTemplateRows: `repeat(${rows}, 1fr)`,
                            gridTemplateColumns: `repeat(${columns}, 1fr)`,
                        }}
                    >
                        {shelves &&
                            shelves[currentShelfIndex].map((slot, index) => (
                                <MiniBox
                                    key={slot.id}
                                    slot={slot}
                                    setProductPopup={setProductPopup}
                                    setShowPopup={setShowPopup}
                                    setPopupData={setPopupData}
                                    label={slot?.label}
                                    product={
                                        slot.productId
                                            ? newProducts.find(
                                                  (p) => p.id === slot.productId
                                              )
                                            : null
                                    }
                                />
                                // <div key={index} className="shelf-slot">
                                //     {slot ? (
                                //         <div className="product-box">Produto</div>
                                //     ) : null}
                                //     <div className="slot-label">
                                //         {getLabel(index)}
                                //     </div>
                                // </div>
                            ))}
                    </div>
                </div>

                {/* Barra de Status Fixa */}
                <div className="status-bar">
                    <h3>Informações do Robô</h3>

                    <div
                        className={`robot-status ${getRobotStatusColor(
                            starboxStatus.status
                        )}`}
                    >
                        <span>{starboxStatus.status}</span>
                    </div>

                    {/* Seção Posição Atual */}
                    <div className="status-section">
                        <h4>Posição Atual</h4>
                        <p>X: {starboxStatus.x}</p>
                        <p>Y: {starboxStatus.y}</p>
                        <p>
                            Garra:{" "}
                            {starboxStatus.z > 500 ? "Avançada" : "Recuada"}
                        </p>
                    </div>

                    {/* Seção Fila de Tarefas */}
                    <div className="status-section">
                        <h4>Fila de Tarefas</h4>
                        <div className="task-list-container">
                            <ul>
                                {starboxTasks
                                    ?.filter(
                                        (task) =>
                                            task.status === 0 ||
                                            task.status === 1
                                    )
                                    .map(renderTask)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* popup */}
            <div
                className={`config-modal-bg ${showPopup ? "shown" : ""}`}
                onClick={() => {
                    setShowPopup(false);
                }}
            >
                <div
                    className="extract-modal arrow-controls-container"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    {/* {starboxStatus.status !== "Online" ? (
                        <div className="config-loading-container">
                            <Loader />
                        </div>
                    ) : (
                        ""
                    )} */}
                    <div className="edit-slot-title">
                        Configuração de <strong>{popupData?.label}</strong>
                    </div>
                    <div className="robot-coordinates-container">
                        <div className="robot-coordinates">
                            <p>Posição atual do robô</p>
                            <div className="robot-positions">
                                <div className="robot-position-container">
                                    X
                                    <div className="robot-position">
                                        {starboxStatus.x}
                                    </div>
                                </div>
                                <div className="robot-position-container">
                                    Y
                                    <div className="robot-position">
                                        {starboxStatus.y}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="robot-coordinates saved">
                            <p>Posição do slot salva</p>
                            <div className="robot-positions">
                                <div className="robot-position-container">
                                    X
                                    <input
                                        className="robot-position"
                                        value={popupData?.x}
                                        onChange={(ev) => {
                                            setPositionChanged(true);
                                            setPopupData({
                                                ...popupData,
                                                x: ev.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="robot-position-container">
                                    Y
                                    <input
                                        className="robot-position"
                                        value={popupData?.y}
                                        onChange={(ev) => {
                                            setPositionChanged(true);
                                            setPopupData({
                                                ...popupData,
                                                y: ev.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="arrows">
                        <div className="arrows-subtitle"></div>
                        <div className="arrows-subtitle mini"></div>
                        {/* Setas maiores */}
                        <FaArrowUp
                            className="arrow arrow-up"
                            onClick={() => moveRobot("y", 1000)}
                        />
                        <FaArrowDown
                            className="arrow arrow-down"
                            onClick={() => moveRobot("y", -1000)}
                        />
                        <FaArrowLeft
                            className="arrow arrow-left"
                            onClick={() => moveRobot("x", -1000)}
                        />
                        <FaArrowRight
                            className="arrow arrow-right"
                            onClick={() => moveRobot("x", 1000)}
                        />

                        {/* Setas menores */}
                        <FaArrowUp
                            className="arrow-small arrow-up-small"
                            onClick={() => moveRobot("y", 100)}
                        />
                        <FaArrowDown
                            className="arrow-small arrow-down-small"
                            onClick={() => moveRobot("y", -100)}
                        />
                        <FaArrowLeft
                            className="arrow-small arrow-left-small"
                            onClick={() => moveRobot("x", -100)}
                        />
                        <FaArrowRight
                            className="arrow-small arrow-right-small"
                            onClick={() => moveRobot("x", 100)}
                        />
                    </div>

                    <div className="claw-controls">
                        <button
                            className="claw-button"
                            onClick={handleAdvance}
                            disabled={starboxStatus.z > 500}
                        >
                            Avançar Garra
                        </button>
                        <button
                            className="claw-button"
                            onClick={handleRetract}
                            disabled={starboxStatus.z < 500}
                        >
                            Recuar Garra
                        </button>
                    </div>
                    <div
                        className="starbox-save-btn"
                        onClick={() => {
                            setStarboxStatus((status) => {
                                return { ...status, status: "Operando" };
                            });
                            fetch("/api/starbox/createtask", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    body: { x: popupData?.x, y: popupData?.y },
                                    text: `Mover para slot ${popupData?.label}`,
                                    type: 3,
                                }),
                            });
                        }}
                    >
                        Mover até local salvo
                    </div>

                    <div
                        className="starbox-save-btn"
                        onClick={() => {
                            fetch("/api/starbox/updateslot", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    ...popupData,
                                    x: starboxStatus.x,
                                    y: starboxStatus.y,
                                }),
                            });
                            setShowPopup(false);
                        }}
                    >
                        Salvar local atual
                    </div>
                    {positionChanged ? (
                        <div
                            className={`starbox-save-btn save-modified`}
                            onClick={() => {
                                fetch("/api/starbox/updateslot", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        ...popupData,
                                        x: popupData.x,
                                        y: popupData.y,
                                    }),
                                });
                                setShowPopup(false);
                            }}
                        >
                            Salvar local modificado
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div
                className={`config-modal-bg ${productPopup ? "shown" : ""}`}
                onClick={() => {
                    setProductPopup(false);
                }}
            >
                <div
                    className="extract-modal arrow-controls-container"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="edit-slot-title">
                        Produto de <strong>{popupData?.label}</strong>
                    </div>

                    {popupData?.productId ? (
                        <>
                            <img
                                className="starbox-product-img"
                                src={`https://orizom.com.br/uploads/thumbs/${
                                    adminProducts.find(
                                        (p) => p.id === popupData?.productId
                                    ).photos[0]
                                }`}
                            />
                            <div className="starbox-product-sku">
                                {
                                    adminProducts.find(
                                        (p) => p.id === popupData?.productId
                                    ).sku
                                }
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <div className="admin-user-groups">
                        <ReactTags
                            classNames={{
                                // tags: "admin-product-tags",
                                tagInputField:
                                    "admin-product-tags-input-starbox",
                                selected: "admin-tag-container",
                                suggestions: "starbox-suggestions",
                            }}
                            suggestions={adminProducts.map((prod) => {
                                return {
                                    id: prod.sku,
                                    text: prod.sku,
                                    _id: prod.id,
                                };
                            })}
                            tags={[]}
                            delimiters={[188, 13]}
                            handleAddition={(tag) => {
                                setPopupData((data) => {
                                    return { ...data, productId: tag._id };
                                });
                            }}
                            handleDrag={(tag, currPos, newPos) => {}}
                            inputFieldPosition="inline"
                            placeholder="Pesquisar por sku"
                            autofocus={false}
                        />
                    </div>
                    <div
                        className="starbox-save-btn"
                        onClick={() => {
                            fetch("/api/starbox/updateslot", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(popupData),
                            });
                            setProductPopup(false);
                        }}
                    >
                        Salvar alterações
                    </div>
                </div>
            </div>
        </>
    );
};

export default Starbox;
