import React, { forwardRef, useEffect, useState } from "react";
import "./Dashboard.css";
import { TypeAnimation } from "react-type-animation";
import SlotNumber from "../../../components/SlotNumber/SlotNumber";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import Loader from "../../../components/Loader/Loader.js";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import MiniBox from "../../../components/MiniBox/MiniBox.jsx";
registerLocale("pt-BR", pt);

export default function AdminDashboard(props) {
    const [dateRange, setDateRange] = useState([
        new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
        new Date(),
    ]);
    const [fullDateSelection, endDate] = dateRange;

    const { adminOrders, adminPayments, onlineUsers, adminUsers } =
        useContext(UserContext);

    const hour = new Date().getHours();

    const [filteredOrders, setFilteredOrders] = useState(null);
    const [filteredPayments, setFilteredPayments] = useState(null);
    const [days, setDays] = useState(1);

    const [selected, setSelected] = useState(0);

    const [hideInfo, setHideInfo] = useState(false);

    const dayOptions = [
        { name: "Hoje", days: 1 },
        { name: "7 dias", days: 7 },
        { name: "15 dias", days: 15 },
        { name: "30 dias", days: 30 },
        { name: "60 dias", days: 60 },
    ];

    useEffect(() => {
        if (adminOrders?.length)
            setFilteredOrders(
                [...adminOrders]?.filter((order) => {
                    let start =
                        days === "custom"
                            ? fullDateSelection
                            : new Date(Date.now() - days * 24 * 60 * 60 * 1000);

                    let correctedEnd = new Date(endDate);
                    correctedEnd.setDate(correctedEnd.getDate() + 1);

                    let end =
                        days === "custom"
                            ? correctedEnd
                            : new Date(Date.now() + 12 * 60 * 60 * 1000);
                    return (
                        [0, 1, 5].includes(order.status) &&
                        start < new Date(order.createdAt) &&
                        new Date(order.createdAt) < end &&
                        (days === 1
                            ? new Date(order.createdAt).getDay() ===
                              new Date().getDay()
                            : true)
                    );
                })
            );
    }, [adminOrders, days, fullDateSelection, endDate]);

    useEffect(() => {
        if (adminPayments?.length)
            setFilteredPayments(
                [...adminPayments]?.filter((order) => {
                    let start =
                        days === "custom"
                            ? fullDateSelection
                            : new Date(Date.now() - days * 24 * 60 * 60 * 1000);

                    let correctedEnd = new Date(endDate);
                    correctedEnd.setDate(correctedEnd.getDate() + 1);

                    let end =
                        days === "custom"
                            ? correctedEnd
                            : new Date(Date.now() + 12 * 60 * 60 * 1000);

                    return (
                        start < new Date(order.updatedAt) &&
                        new Date(order.updatedAt) < end &&
                        (days === 1
                            ? new Date(order.updatedAt).getDay() ===
                              new Date().getDay()
                            : true)
                    );
                })
            );
    }, [adminPayments, days, fullDateSelection, endDate]);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={`admin-dashboard-day ${
                days === "custom" ? "selected" : ""
            }`}
            onClick={onClick}
            ref={ref}
        >
            {days === "custom" ? value : "Data personalizada"}
        </div>
    ));

    return (
        <div className={`admin-dashboard ${hideInfo ? "hide-info" : ""}`}>
            {/* <MiniBox /> */}
            <div
                className={`admin-dashboard-loading ${
                    adminOrders.length ? "" : "shown"
                }`}
            >
                <Loader />
                <TypeAnimation
                    sequence={[
                        `Bem-vindo(a), ${props.user.name.split(" ")[0]}!\n`,
                        100,
                        `Bem-vindo(a), ${
                            props.user.name.split(" ")[0]
                        }!\nCarregando Dashboard...`,
                        1000,
                        `Bem-vindo(a), ${
                            props.user.name.split(" ")[0]
                        }!\nBuscando informações...`,
                        1000,
                    ]}
                    repeat={100}
                />
            </div>

            <div className="admin-dashboard-days">
                <div className="hide-info-btn">
                    <div
                        className={`eye ${hideInfo ? "" : "open"}`}
                        onClick={() => {
                            setHideInfo(!hideInfo);
                        }}
                    >
                        <div className="eye__base">
                            <div className="eye__base__view">
                                <div className="eye__base__view__iris" />
                                <div className="eye__base__view__pupil" />
                            </div>
                        </div>
                        <div className="eye__lid">
                            <div className="eye__lid__mask" />
                            <div className="eye__lid__lashes">
                                <div className="eye__lid__lashes__line" />
                                <div className="eye__lid__lashes__hair" />
                            </div>
                        </div>
                    </div>
                </div>
                {dayOptions.map((day) => {
                    return (
                        <div
                            className={`admin-dashboard-day ${
                                days === day.days ? "selected" : ""
                            }`}
                            onClick={() => {
                                setDays(day.days);
                                setDateRange([
                                    new Date(
                                        Date.now() -
                                            day.days * 24 * 60 * 60 * 1000
                                    ),
                                    new Date(),
                                ]);
                            }}
                        >
                            {day.name}
                        </div>
                    );
                })}
                <div>
                    <ReactDatePicker
                        selectsRange={true}
                        // fullDateSelection={fullDateSelection}
                        startDate={fullDateSelection}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                            // console.log(update[1]);
                            if (update[1] !== null) setDays("custom");
                        }}
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomInput />}
                        calendarClassName="admin-calendar"
                    />
                </div>
            </div>
            {filteredOrders ? (
                <div className="admin-dashboard-info">
                    <div
                        className={`admin-dashboard-block ${
                            selected === 0 ? "selected" : ""
                        }`}
                        onClick={() => setSelected(0)}
                    >
                        <div className="admin-dashboard-block-title">
                            Vendas
                        </div>
                        <div className="admin-dashboard-value">
                            <SlotNumber
                                number={filteredOrders?.length}
                                height={24}
                                autoStart={true}
                                startDelay={1000}
                            />
                        </div>
                    </div>

                    <div
                        className={`admin-dashboard-block ${
                            selected === 1 ? "selected" : ""
                        }`}
                        onClick={() => setSelected(1)}
                    >
                        <div className="admin-dashboard-block-title">
                            Valor total
                        </div>
                        <div className="admin-dashboard-value">
                            <span className="admin-dashboard-sign">R$</span>
                            <SlotNumber
                                number={Math.round(
                                    filteredOrders?.reduce(
                                        (prev, curr) => prev + curr.price,
                                        0
                                    )
                                )}
                                height={24}
                                autoStart={true}
                                startDelay={1000}
                            />
                        </div>
                    </div>

                    <div
                        className={`admin-dashboard-block ${
                            selected === 2 ? "selected" : ""
                        }`}
                        onClick={() => setSelected(2)}
                    >
                        <div className="admin-dashboard-block-title">
                            Faturamento
                        </div>
                        <div className="admin-dashboard-value">
                            <span className="admin-dashboard-sign">R$</span>
                            <SlotNumber
                                number={Math.round(
                                    filteredOrders?.reduce(
                                        (prev, curr) => prev + curr.earned,
                                        0
                                    )
                                )}
                                height={24}
                                autoStart={true}
                                startDelay={1000}
                            />
                        </div>
                    </div>
                    <div
                        className={`admin-dashboard-block ${
                            selected === 3 ? "selected" : ""
                        }`}
                        onClick={() => setSelected(3)}
                    >
                        <div className="admin-dashboard-block-title">
                            Aportes
                        </div>
                        <div className="admin-dashboard-value">
                            <span className="admin-dashboard-sign">R$</span>
                            <SlotNumber
                                number={Math.round(
                                    filteredPayments?.reduce(
                                        (prev, curr) => prev + curr.paidValue,
                                        0
                                    )
                                )}
                                height={24}
                                autoStart={true}
                                startDelay={1000}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="admin-dashboard-details">
                <div className="admin-dashboard-details-list">
                    {adminUsers
                        .map((u) => {
                            return {
                                ...u,
                                orders: filteredOrders?.filter(
                                    (o) => o.userId === u.id
                                ).length,
                                totalValue: filteredOrders?.reduce(
                                    (prev, curr) => {
                                        return (
                                            prev +
                                            (curr.userId === u.id
                                                ? curr.price
                                                : 0)
                                        );
                                    },
                                    0
                                ),
                                totalEarned: filteredOrders?.reduce(
                                    (prev, curr) => {
                                        if (curr.userId === u.id)
                                            return prev + curr.earned;
                                        return prev;
                                    },
                                    0
                                ),
                                totalPayments: filteredPayments?.reduce(
                                    (prev, curr) => {
                                        if (curr.userId === u.id)
                                            return prev + curr.paidValue;
                                        return prev;
                                    },
                                    0
                                ),
                            };
                        })
                        .sort((a, b) => {
                            if (selected === 0) return b.orders - a.orders;
                            if (selected === 1)
                                return b.totalValue - a.totalValue;
                            if (selected === 2)
                                return b.totalEarned - a.totalEarned;
                            return b.totalPayments - a.totalPayments;
                        })
                        .map((u, i) => {
                            return (
                                <div className="dashboard-detail-item">
                                    <div className="dashboard-user-colocation">
                                        {i + 1}.
                                    </div>
                                    <div
                                        className={`dashboard-detail-value ${
                                            selected !== 0 ? "money" : ""
                                        }`}
                                    >
                                        {selected !== 0 ? (
                                            <div className="rssign">R$</div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <SlotNumber
                                            number={
                                                selected === 0
                                                    ? u.orders
                                                    : selected === 1
                                                    ? u.totalValue
                                                    : selected === 2
                                                    ? Math.round(u.totalEarned)
                                                    : u.totalPayments
                                            }
                                            height={14}
                                            autoStart={true}
                                            startDelay={1000}
                                        />
                                    </div>{" "}
                                    <div className="admin-dashboard-email">
                                        {u.email}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="online-users">
                <div className="online-users-title">
                    Usuários online no momento
                </div>
                <div className="online-user-list">
                    {onlineUsers?.map((u) => {
                        let user = adminUsers?.find((a) => a.id === u);
                        return (
                            <div className="online-user">
                                <div className="online-user-name">
                                    {user?.name.split(" ")[0]}{" "}
                                    <span className="admin-dashboard-email">
                                        {user?.name
                                            .split(" ")
                                            .slice(1)
                                            .join(" ")}
                                    </span>
                                </div>
                                <div className="admin-dashboard-email">
                                    {user?.email}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
