import React, { useEffect, useState, Fragment, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import BarcodeReader from "react-barcode-reader";

import Loader from "../../../components/Loader/Loader";

import "./Orders.css";

import marketplaces from "../../../config/marketplaces.json";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";

import queue from "sync-queue";
import { sleep } from "../../../util/utils.js";

const labelQueue = new queue();

export default function AdminOrders() {
    const {
        adminOrders,
        adminUsers,
        adminProducts,
        autoPrint,
        setAutoPrint,
        plpPrinter,
        etqPrinter,
        zebraPrint,
        adminBoxes,
    } = useContext(UserContext);

    const pdfjsLib = window["pdfjs-dist/build/pdf"];

    const ordersRef = useRef("");

    // const [plpPrinter, etqPrinter, zebraPrint] = useOutletContext();

    const statuses = {
        0: { name: "Aguardando", color: "#ae2f2f" },
        1: { name: "Solicitado", color: "#6200ff" },
        2: { name: "Pronto", color: "#0080ff" },
        3: { name: "A gravar", color: "#ff970f" },
        4: { name: "Gravado", color: "#0080ff" },
        9: { name: "Embalado", color: "rgb(10 119 10)" },
    };

    const [orders, setOrders] = useState(null);

    const [autoOrders, setAutoOrders] = useState(null);

    const [focused, setFocused] = useState({});

    const [hovered, setHovered] = useState(false);

    const [search, setSearch] = useState(undefined);
    const [ordersSearch, setOrdersSearch] = useState([]);

    const [onlySelected, setOnlySelected] = useState(false);

    const [cancelModal, setCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [modalOrder, setModalOrder] = useState({});

    const [modalGrouped, setModalGrouped] = useState(false);

    // const [updateTimeout, setUpdateTimeout] = useState(null);

    const [printingPLPs, setPrintingPLPs] = useState(false);
    const [printingNFes, setPrintingNFes] = useState(false);
    const [printingEtqs, setPrintingEtqs] = useState(false);
    const [printingPnfs, setPrintingPnfs] = useState(false);
    const [printingAll, setPrintingAll] = useState(false);

    const [modalPlp, setModalPlp] = useState(false);
    const [modalNfe, setModalNfe] = useState(false);
    const [plp, setPlp] = useState(false);
    const [modalEntranceAuth, setModalEntranceAuth] = useState(false); //(Gab)
    const [fullDateSelection, setfullDateSelection] = useState(null); //(Gab)
    const [entranceAuth, setEntranceAuth] = useState(""); //estado entrada (Gab)

    const [modalOrderObj, setModalOrderObj] = useState({});

    const [labelData, setLabelData] = useState({});

    const [filter, setFilter] = useState(null);
    //Gab
    const isFull = (order) => {
        return order.marketplace === 82;
    };
    //fim

    const ordersEls = useRef(new Array());

    const getPlpImage = (plpUrl) => {
        return new Promise(async (resolve, reject) => {
            if (!plpUrl) reject();

            const plpResponse = await fetch(`/uploads/orders/${plpUrl}`);
            let plpData = await plpResponse.text();
            if (!plpData) reject();

            //Gab

            const isFull2 = (zplContent) => {
                const firstXA = zplContent.indexOf("^XA");
                const secondXA = zplContent.indexOf("^XA", firstXA + 1);
                return secondXA !== -1; // É FULL se encontrar mais de uma etiqueta
            };

            // Função para extrair a primeira etiqueta de um arquivo ZPL
            const extractFirstZplLabel = (zplContent) => {
                const startLabel = zplContent.indexOf("^XA");
                const endLabel = zplContent.indexOf("^XZ", startLabel) + 4; // Inclui o tamanho de '^XZ'
                return startLabel !== -1 && endLabel !== -1
                    ? zplContent.slice(startLabel, endLabel)
                    : null;
            };

            // Verifica se é FULL e extrai a primeira etiqueta se necessário
            if (isFull2(plpData)) {
                console.log(
                    "Arquivo ZPL do FULL detectado, extraindo a primeira etiqueta."
                );
                plpData = extractFirstZplLabel(plpData);
            }

            //Fim

            const response = await fetch(
                "https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/",
                {
                    method: "POST",
                    body: plpData,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );

            let blob = await response.blob();

            let text = await blob.text();

            if (text.includes("ERROR")) resolve("/img/plperror.png");

            blobToBase64(blob, function (image) {
                resolve(`data:image/png;base64,${image}`);
            });
        });
    };

    var blobToBase64 = function (blob, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(",")[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    };

    useEffect(() => {
        updateSearch();
    }, [orders, search, onlySelected, filter]);

    useEffect(() => {
        return function cleanup() {
            setAutoPrint(false);
        };
    }, []);

    const updateSearch = async () => {
        if (!orders) return;

        if (
            onlySelected &&
            !ordersRef.current.some((e) => e.checked === true)
        ) {
            return setOnlySelected(false);
        }

        if (search || onlySelected) {
            let newOrders = orders.map((e) => {
                return {
                    ...e,
                    user: adminUsers.find((u) => u.id === e.userId),
                };
            });
            if (onlySelected) {
                newOrders = newOrders.filter(
                    (e) => e.checked === true || e.autoPrint
                );
                if (!newOrders.length) {
                    setOnlySelected(false);
                }
            } else {
                newOrders = newOrders.filter((e) => {
                    let src = search.toLowerCase();
                    return (
                        src.includes(e.id) ||
                        `${e.id}`.includes(src) ||
                        Object.values(e.order_products).some((en) => {
                            let prod = adminProducts.find(
                                (ap) => ap.id === en.productId
                            );

                            return (
                                prod?.sku?.toLowerCase().includes(src) ||
                                prod?.name?.toLowerCase().includes(src)
                            );
                        }) ||
                        Object.values(e.order_products).some(
                            (en) =>
                                src.includes(
                                    adminProducts
                                        .find((ap) => ap.id === en.productId)
                                        ?.sku?.toLowerCase()
                                ) ||
                                marketplaces[e.marketplace]?.name
                                    .toLowerCase()
                                    .includes(src) ||
                                adminUsers
                                    .find((u) => u.id === e.userId)
                                    ?.email.toLowerCase()
                                    .includes(src)
                        )
                    );
                });
            }

            let newAutoOrders = newOrders.filter((o) => o.autoPrint);

            newOrders = newOrders.filter((o) => !o?.autoPrint);

            if (filter !== null)
                newOrders = newOrders.filter((o) =>
                    Object.values(o.order_products).some(
                        (op) => op.status === filter
                    )
                );

            setAutoOrders(newAutoOrders);
            setOrdersSearch(newOrders);
        } else {
            let newOrders = orders.map((e) => {
                return {
                    ...e,
                    user: adminUsers.find((u) => u.id === e.userId),
                };
            });

            let newAutoOrders = newOrders.filter((o) => o.autoPrint);

            newOrders = newOrders.filter((o) => !o?.autoPrint);

            if (filter !== null)
                newOrders = newOrders.filter((o) =>
                    Object.values(o.order_products).some(
                        (op) => op.status === filter
                    )
                );

            setAutoOrders(newAutoOrders);
            setOrdersSearch(newOrders);
        }
    };

    useEffect(() => {
        ordersRef.current = orders || [];
    }, [orders]);

    function getEtq(id, sku, name) {
        return `^XA
            ^PW319
            ^FWN
            ^LH0,5
            ^FO245,120^GFA,170,170,5,,::003C0018,007E007E,00FF00FE,00FF00FF,:00FE00FE,007E007E,003C003C,,I07FFE,003JFE,00LF8,03FF80FFE,07F8001FF,0FEJ07F81FCJ03F81F8J01FC1F8K0FC:1F8J01FC1FCJ03F80FEJ07F807F8I0FF,03FF00FFE,00LF8,003JFE,I07IF,,:::^FS
            
            ^BY2
            ^FO35,8 ^BCN,100,N ^FD${`${id}`.padStart(8, "0")}^FS
            ^FO35,125 ^A0,30 ^FD${sku}^FS
            ^FO35,165 ^A0,20 ^FD${(name || "Produto")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}^FS
            ^XZ`;
    }

    function checkIsReady(order) {
        return order.order_products?.every((e) => {
            return (
                e.status === 9 && true
                // (!e.options ||
                //     Object.values(e.options).every((en) => en.status === 1))
            );
        });
    }

    async function setProductStatus(status, product, order, current) {
        try {
            let newOrders = [...orders];
            newOrders.find((o) => o.id === order).order_products[
                newOrders
                    .find((o) => o.id === order)
                    .order_products.findIndex((e) => e.id === product)
            ].status = status;
            setOrders(newOrders);

            fetch("/api/orders/updateproduct", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: product, data: { status: status } }),
            })
                .then(() => {})
                .catch((err) => {
                    throw new Error(err);
                });

            if (checkIsReady(ordersRef.current.find((o) => o.id === order))) {
                if (ordersEls.current[current + 1]) {
                    ordersEls.current[current + 1].focus();
                    setTimeout(
                        () =>
                            ordersEls.current[current + 1].scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            }),
                        1000
                    );
                }
                setOrderStatus(ordersRef.current.find((o) => o.id === order));
            }
        } catch (err) {
            setTimeout(() => setProductStatus(status, product, order), 1000);
            console.error(err);
        }
    }

    async function setOptionStatus(status, product, option, order, current) {
        return;
        try {
            let newOrders = { ...orders };
            newOrders[order.id].products[product.id].options[option.id].status =
                "1";
            setOrders(newOrders);

            fetch("/api/orders/setoptionstatus", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: option.id }),
            });

            if (checkIsReady(newOrders[order.id])) {
                ordersEls.current[current + 1].focus();
                ordersEls.current[current + 1].scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                setOrderStatus(newOrders[order.id]);
            }
        } catch (err) {
            setTimeout(
                () => setOptionStatus(status, product, option, order),
                1000
            );
            console.error(err);
        }
    }

    async function setOrderStatus(order, status) {
        fetch("/api/orders/update", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ids: order.id,
                data: {
                    status: status || 5,
                },
            }),
        })
            .then(() => console.log("ok"))
            .catch(() => {
                setTimeout(() => {
                    setOrderStatus(order, status);
                }, 1000);
            });
    }

    function printPLPs(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                await fetch(`/api/orders/update`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ids: orders.map((e) => e.id),
                        data: { plpPrinted: true },
                    }),
                });

                let plps = [];

                for await (let o of orders) {
                    try {
                        const plpResponse = await fetch(
                            `/uploads/orders/${o.plp}`
                        );
                        let plpData = await plpResponse.text();
                        if (!plpData) continue;

                        let skus = o.order_products
                            .map(
                                (e) =>
                                    adminProducts.find(
                                        (p) => p.id === e.productId
                                    )?.sku
                            )
                            .join(", ");

                        let plp = plpData;

                        plp = plp.replace("^XA^MCY^XZ", "ZPLAMAZONZPL");
                        plp = plp.replace(
                            /\^XA/i,
                            `^XA ^PW1200 ^BY0,0,0 ^FWN
            ^FO760,18^GFA,104,104,4,,:01C008,03E01E,03F03F,::01E01E,,003FF,01IFE,07JF8,0FC00FE,1FI03F,3EI01F,3EJ0F,3EJ0F83EI01F,1FI03F,1FC007E,07FC7FC,01JF,003FF8,,::^FS
            ^FO25,20^BY2^BCN,23,N,N,N^FD${`${o.id}`.padStart(8, 0)}^FS
            ^FO280,25^A0N,20,20^FD Cod: ${`${o.id}`.padStart(8, 0)}^FS
            ^FO420,25^A0N,20,20^FD SKU: ${
                skus.length > 27 ? "Diversos" : skus
            }^FS ^LH,27`
                        );
                        plp = plp.replace(/\^LH\d+,\d+/i, "$0 ^LH,32");
                        plp = plp.replace(/\^XZ/i, "^LH0,0 ^XZ");
                        plp = plp.replace("ZPLAMAZONZPL", "^XA^MCY^XZ");

                        plps.push(plp);
                    } catch (err) {
                        console.log(err);
                    }
                }

                await zebraPrint(plpPrinter, plps.join("\n"));
                setTimeout(() => {
                    resolve("ok");
                }, 200);
                return;
            } catch (err) {
                reject(err);
                return;
            }
        });
    }

    function printNFes(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                await fetch(`/api/orders/update`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ids: orders.map((e) => e.id),
                        data: { nfePrinted: true },
                    }),
                });
                let failed = [];
                for await (let order of orders) {
                    try {
                        await renderAndPrint(order);
                    } catch (err) {
                        failed.push(order.id);
                    }
                }
                if (failed.length)
                    return reject(
                        `Falha ao imprimir a NFE pedidos:\n${failed.join("\n")}`
                    );
                setTimeout(() => {
                    resolve("ok");
                }, 200);
            } catch (err) {
                reject(err);
            }
        });
    }

    const renderAndPrint = (order) => {
        return new Promise(async (resolve, reject) => {
            const response = await fetch(`/uploads/orders/${order.nfe}`);
            const blob = await response.blob();
            let reader = new FileReader();
            reader.onload = (e) => {
                const data = atob(e.target.result.replace(/.*base64,/, ""));
                renderPage(data, order.id)
                    .then(() => {
                        resolve("ok");
                    })
                    .catch((err) => {
                        reject("error");
                    });
            };
            reader.readAsDataURL(blob);
        });
    };

    function renderPage(data, orderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const pdf = await pdfjsLib.getDocument({ data }).promise;
                let page = await pdf.getPage(1);
                const canvas = document.createElement("canvas");
                canvas.setAttribute("className", "canv");
                let viewport = page.getViewport({ scale: 2 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const ctx = canvas.getContext("2d");
                let render_context = {
                    canvasContext: ctx,
                    viewport: viewport,
                };
                await page.render(render_context).promise;
                ctx.font = "bold 30px Montserrat";
                ctx.fillText(
                    `${orderId}`.padStart(8, 0),
                    40,
                    viewport.height - 10
                );
                canvas.toBlob(async (blob) => {
                    await zebraPrint(plpPrinter, blob, "img");
                    canvas.remove();
                    resolve("ok");
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    const printAll = (autoOrders) => {
        return new Promise(async (resolve, reject) => {
            for await (let order of autoOrders) {
                await printPLPs([order]).catch(console.error);
                sleep(100);
                await printNFes([order]).catch(console.error);
            }
            resolve("ok");
        });
    };

    useEffect(() => {
        setOrders(
            adminOrders
                .filter((order) => order.status === 0)
                ?.slice(0, 6500)
                .reverse()
                .map((o) => {
                    return {
                        ...o,
                        checked: ordersRef.current?.find((r) => r.id === o.id)
                            ?.checked,
                    };
                })
        );
        // setAutoOrders(
        //     adminOrders
        //         .filter((order) => order.status === 0)
        //         .slice(0, 6)
        //         .reverse()
        // );
    }, [adminOrders]);

    const selectedQtde =
        orders && Object.values(orders).filter((e) => e.checked).length;

    let ordersSorted = Object.entries(ordersSearch);
    ordersSorted.sort((a, b) => {
        return (
            marketplaces[a[1].marketplace]?.adminOrder -
            marketplaces[b[1].marketplace]?.adminOrder
        );
    });

    const labelRef = useRef(null);

    const printLabel = (orders) => {
        orders.forEach((order) => {
            labelQueue.place(() => {
                setLabelData({
                    order: order.id,
                    name: order.user?.name?.split(" ")?.slice(0, 2).join(" "),
                    phone: order.user?.phone,
                });
                setTimeout(() => {
                    html2canvas(labelRef.current)
                        .then((canvas) => {
                            canvas.toBlob(async (blob) => {
                                await zebraPrint(plpPrinter, blob, "img");
                                canvas.remove();
                                labelQueue.next();
                            });
                        })
                        .catch(() => {
                            alert(
                                "Falha ao gerar a etiqueta do pedido " +
                                    order.id
                            );
                            labelQueue.next();
                        });
                }, 100);
            });
        });
    };

    return (
        <>
            <div className="order-label" ref={labelRef}>
                <div className="order-label-title">
                    OBRIGADO POR SUA COMPRA!
                </div>
                <div className="order-label-text">
                    Nós da <strong>{labelData?.name || "Orizom"}</strong>{" "}
                    agradecemos pela confiança depositada em nossa empresa, nos
                    escolhendo para essa tão complexa tarefa que é suprir suas
                    expectativas!
                </div>
                <div className="order-label-title">NÃO ABRA RECLAMAÇÃO!</div>
                <div className="order-label-text">
                    Caso ocorram quaisquer divergências em seu pedido,{" "}
                    <strong>não abra reclamação.</strong> Além de demorar mais,
                    me prejudica muito! Fale comigo direto pelo número do{" "}
                    <strong>Whatsapp</strong> ou lendo o <strong>QRCode</strong>{" "}
                    abaixo, que te respondo na mesma hora para resolver seu
                    problema!
                </div>
                <div className="order-label-phone">
                    {labelData.phone
                        ? `(${labelData.phone?.slice(
                              0,
                              2
                          )}) ${labelData.phone?.slice(
                              2,
                              7
                          )}-${labelData?.phone.slice(7, 20)}`
                        : "(11) 99999-9999"}
                    <img src={`/img/order-whatsapp.png`} alt />
                </div>
                <div className="order-label-qr">
                    <QRCode
                        value={`https://wa.me/55${
                            labelData.phone || "11999999999"
                        }`}
                        size={110}
                        eyeRadius={5}
                        // logoImage="/img/qrcode/logo.png"
                        // logoHeight={40}
                        // logoWidth={40}
                        // removeQrCodeBehindLogo={true}
                        logoPadding={0}
                    />
                </div>
                <div className="order-label-starbox">
                    <img src="/img/starbox.png" />
                </div>
                <div className="order-label-number">
                    {`${labelData.order || 0}`.padStart(6, "0")}
                </div>
            </div>

            <div
                className={`order-modal-admin-bg-2 ${
                    modalGrouped ? "shown" : ""
                }`}
                onClick={() => setModalGrouped(false)}
            >
                <div
                    className="order-modal-admin order-modal-grouped"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    {orders?.length &&
                        Object.entries(
                            orders
                                .filter((o) => o.marketplace !== 82)
                                ?.reduce((acc, e) => {
                                    e.order_products.forEach((op) => {
                                        // if (!acc) acc = {};
                                        if (!acc[op.productId])
                                            acc[op.productId] = 0;
                                        acc[op.productId]++;
                                    });
                                    return acc;
                                }, {})
                        )
                            .sort((a, b) => b[1] - a[1])
                            .map((o) => {
                                let product = adminProducts.find(
                                    (pr) => pr.id == o[0]
                                );
                                return (
                                    <div className="grouped-product">
                                        <img
                                            src={
                                                "/uploads/thumbs/" +
                                                product?.photos[0]
                                            }
                                        />
                                        <div className="grouped-sku">
                                            {product?.sku}
                                        </div>
                                        <div className="grouped-qtty">
                                            {o[1]}
                                        </div>
                                    </div>
                                );
                            })}
                </div>
            </div>

            <div
                className={`order-modal-admin-bg-2 ${
                    modalNfe || modalEntranceAuth ? "shown" : ""
                }`}
                onClick={() => {
                    setModalNfe(false);
                    setModalEntranceAuth(false);
                }}
            >
                <div
                    className="order-modal-admin order-modal-plp order-modal-nfe"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <iframe
                        src={
                            `${window.location.protocol}//${
                                window.location.hostname == "localhost"
                                    ? `${window.location.hostname}:5000`
                                    : window.location.hostname
                            }/uploads/orders/` +
                            (modalEntranceAuth
                                ? modalOrderObj.entranceAuth
                                : modalOrderObj.nfe)
                        }
                        type="application/pdf"
                        width="400px"
                        height="550px"
                    />
                </div>
            </div>

            <div
                className={`order-modal-admin-bg-2 ${modalPlp ? "shown" : ""}`}
                onClick={() => {
                    setModalPlp(false);
                    setPlp(null);
                }}
            >
                <div
                    className="order-modal-admin order-modal-plp"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className={`plp-loading ${plp ? "hidden" : ""}`}>
                        <Loader />
                    </div>
                    <img className="plp-img" src={plp} />
                </div>
            </div>
            <div
                className={`admin-cancel-modal-bg ${
                    cancelModal ? "shown" : ""
                }`}
                onClick={() => setCancelModal(false)}
            >
                <div
                    className="admin-cancel-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    Qual é o motivo do cancelamento?
                    <textarea
                        onChange={(ev) => setCancelReason(ev.target.value)}
                        value={cancelReason}
                    />
                    <div
                        className="admin-cancel-modal-btn"
                        onClick={() => {
                            if (!cancelReason)
                                return alert("Preencha o motivo");

                            fetch("/api/orders/cancel", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    orderId: modalOrder.id,
                                    reason: cancelReason,
                                }),
                            });

                            let newOrders = ordersRef.current;
                            newOrders = newOrders.filter(
                                (e) => e.id !== modalOrder.id
                            );
                            setOrders(newOrders);

                            setCancelReason("");
                            setCancelModal(false);
                        }}
                    >
                        Confirmar cancelamento
                    </div>
                </div>
            </div>
            <div className="admin-orders-container">
                <div className="admin-order-container">
                    <Loader
                        className={`${adminOrders.length ? "hidden" : ""}`}
                    />
                    <div
                        className="admin-order-search"
                        style={{ zIndex: hovered ? 9999 : 99999 }}
                    >
                        <div className="admin-order-search-container">
                            <div
                                className={`admin-order-selected-btns ${
                                    selectedQtde ? "shown" : ""
                                }`}
                            >
                                <div
                                    className={`admin-selected-orders ${
                                        onlySelected ? "only-selected" : ""
                                    }`}
                                    onClick={() =>
                                        setOnlySelected(
                                            (onlySelected) => !onlySelected
                                        )
                                    }
                                >
                                    <div>
                                        {onlySelected ? (
                                            <div>Mostrando</div>
                                        ) : (
                                            ""
                                        )}
                                        <strong>{selectedQtde}</strong> Selec.
                                    </div>
                                </div>
                                <div
                                    className={`admin-selected-btn ${
                                        printingNFes ? "printing-show" : ""
                                    }`}
                                    onClick={() => {
                                        setPrintingNFes(true);
                                        printNFes(
                                            ordersSorted
                                                .filter((e) => e[1].checked)
                                                .map((e) => e[1])
                                        )
                                            .then(() => setPrintingNFes(false))
                                            .catch((err) => {
                                                alert(err);
                                                setPrintingNFes(false);
                                            });
                                    }}
                                >
                                    <div className="printing-loading">
                                        <Loader />
                                    </div>
                                    Imprimir
                                    <br />
                                    NFEs
                                </div>

                                <div
                                    className={`admin-selected-btn ${
                                        printingPLPs ? "printing-show" : ""
                                    }`}
                                    onClick={() => {
                                        setPrintingPLPs(true);
                                        printPLPs(
                                            ordersSorted
                                                .filter((e) => e[1].checked)
                                                .map((e) => e[1])
                                        )
                                            .then(() => setPrintingPLPs(false))
                                            .catch((err) => {
                                                alert(err);
                                                setPrintingPLPs(false);
                                            });
                                    }}
                                >
                                    <div className="printing-loading">
                                        <Loader />
                                    </div>
                                    Imprimir
                                    <br />
                                    PLPs
                                </div>

                                <div
                                    className={`admin-selected-btn ${
                                        printingEtqs ? "printing-show" : ""
                                    }`}
                                    onClick={() => {
                                        setPrintingEtqs(true);
                                        try {
                                            zebraPrint(
                                                etqPrinter,
                                                ordersSorted
                                                    .filter((e) => e[1].checked)
                                                    .map((order) => {
                                                        return order[1].order_products.map(
                                                            (e) =>
                                                                getEtq(
                                                                    e.product
                                                                        .id,
                                                                    e.product
                                                                        .sku,
                                                                    e.product
                                                                        .name
                                                                )
                                                        );
                                                    })
                                                    .join("\n")
                                            );
                                            setTimeout(
                                                () => setPrintingEtqs(false),
                                                1000
                                            );
                                        } catch (err) {
                                            console.log(err);
                                            setPrintingEtqs(false);
                                        }
                                        // let newOrders = Object.values(orders);

                                        // newOrders.forEach(e => {
                                        //     if (e.checked === true) {
                                        //         Object.values(e.products).forEach(en => {
                                        //             zebraPrint(etqPrinter, getEtq(en.productId, en.sku, en.name));
                                        //         })
                                        //         // zebraPrint(plpPrinter, `https://orizom.com.br/uploads/pdfs${e.plp}`, 'pdf');
                                        //     }
                                        // });
                                    }}
                                >
                                    <div className="printing-loading">
                                        <Loader />
                                    </div>
                                    Imprimir
                                    <br />
                                    Etiquetas
                                </div>
                                <div
                                    className={`admin-selected-btn ${
                                        printingPnfs ? "printing-show" : ""
                                    }`}
                                    onClick={() => {
                                        setPrintingPnfs(true);
                                        printLabel(
                                            ordersSorted
                                                .filter((e) => e[1].checked)
                                                .map((e) => e[1])
                                        );
                                        setTimeout(
                                            () => setPrintingPnfs(false),
                                            2000
                                        );
                                    }}
                                >
                                    <div className="printing-loading">
                                        <Loader />
                                    </div>
                                    Imprimir
                                    <br />
                                    Panfletos
                                </div>
                            </div>
                            <div className="admin-order-search-input">
                                <input
                                    placeholder="Pesquise por nº do pedido, SKU ou marketplace"
                                    value={search}
                                    onChange={(ev) => {
                                        setSearch(ev.target.value);
                                        setOnlySelected(false);
                                    }}
                                />
                                <div className="admin-search-qtty">
                                    <div
                                        className="admin-order-group-button"
                                        onClick={() => setModalGrouped(true)}
                                    >
                                        Totais
                                    </div>
                                    <div
                                        className="admin-order-filter"
                                        style={{
                                            backgroundColor:
                                                statuses[filter]?.color ||
                                                "#1b1b1b",
                                        }}
                                    >
                                        Filtrar
                                        <div className="admin-filter-select">
                                            <div
                                                className={
                                                    filter === null
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => setFilter(null)}
                                            >
                                                Sem filtro
                                            </div>
                                            <div
                                                className={
                                                    filter === 4
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => setFilter(4)}
                                                style={{
                                                    backgroundColor:
                                                        statuses[4].color,
                                                }}
                                            >
                                                Prontos
                                            </div>
                                            <div
                                                className={
                                                    filter === 1
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => setFilter(1)}
                                                style={{
                                                    backgroundColor:
                                                        statuses[1].color,
                                                }}
                                            >
                                                Estoque
                                            </div>
                                            <div
                                                className={
                                                    filter === 3
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => setFilter(3)}
                                                style={{
                                                    backgroundColor:
                                                        statuses[3].color,
                                                    color: "#000",
                                                }}
                                            >
                                                Gravação
                                            </div>
                                            <div
                                                className={
                                                    filter === 0
                                                        ? "filter-selected"
                                                        : ""
                                                }
                                                onClick={() => setFilter(0)}
                                                style={{
                                                    backgroundColor:
                                                        statuses[0].color,
                                                }}
                                            >
                                                Pendentes
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {Object.keys(ordersSearch).length +
                                            (autoPrint
                                                ? autoOrders?.length
                                                : 0)}{" "}
                                        Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BarcodeReader
                        onScan={(data) => {
                            // return alert(data);
                            if (focused === "autoprint") {
                                // return alert(data);
                                // alert(Number(data));
                                const theOrder = ordersRef.current?.find(
                                    (o) =>
                                        o.id === Number(data) &&
                                        o.autoPrint === true
                                );
                                if (theOrder) {
                                    // alert("ok");
                                    setOrderStatus(theOrder);
                                }
                                return;
                            }

                            if (!focused) return;

                            const theOrder = ordersRef.current?.find(
                                (o) => o.id === focused.id
                            );

                            if (!theOrder) return;

                            if (!theOrder.plpPrinted || !theOrder.nfePrinted) {
                                alert("Imprima a PLP e NFe antes!");
                                return;
                            }

                            if (
                                Object.values(focused.order_products).some(
                                    (e) => Number(e.product.id) === Number(data)
                                )
                            ) {
                                //alert(Number(data));
                                let object = orders
                                    .find((o) => o.id === focused.id)
                                    ?.order_products.find(
                                        (e) =>
                                            Number(e.productId) ===
                                                Number(data) && e.status !== 9
                                    );
                                // alert(JSON.stringify(object));
                                if (object)
                                    setProductStatus(
                                        9,
                                        object.id,
                                        focused.id,
                                        ordersSorted.findIndex(
                                            (e) => e[1].id === focused.id
                                        )
                                    );
                            }
                            console.log();
                        }}
                    />
                    {Object.keys(ordersSearch).length !== 0
                        ? ordersSorted.map(([key, order], i) => {
                              let marketplace;
                              if (
                                  !ordersSorted[i - 1] ||
                                  (ordersSorted[i - 1] &&
                                      ordersSorted[i - 1][1].marketplace !==
                                          order.marketplace)
                              ) {
                                  marketplace = marketplaces[order.marketplace];
                              }

                              if (!order) return <></>;

                              let clickTimeout;

                              return (
                                  <Fragment key={order.id}>
                                      {marketplace ? (
                                          <>
                                              {i !== 0 ? (
                                                  <div
                                                      className="order-marketplace-ending"
                                                      style={{
                                                          borderColor:
                                                              marketplace &&
                                                              marketplaces[
                                                                  ordersSorted[
                                                                      i - 1
                                                                  ][1]
                                                                      .marketplace
                                                              ]
                                                                  ? marketplaces[
                                                                        ordersSorted[
                                                                            i -
                                                                                1
                                                                        ][1]
                                                                            .marketplace
                                                                    ].color
                                                                  : "#fff",
                                                      }}
                                                  ></div>
                                              ) : (
                                                  ""
                                              )}
                                              <div
                                                  className="order-marketplace-separator"
                                                  style={{
                                                      borderColor:
                                                          marketplace &&
                                                          marketplaces[
                                                              order.marketplace
                                                          ]
                                                              ? marketplaces[
                                                                    order
                                                                        .marketplace
                                                                ].color
                                                              : "#fff",
                                                  }}
                                              >
                                                  <img
                                                      src={
                                                          marketplaces[
                                                              order.marketplace
                                                          ]?.img ||
                                                          marketplaces[
                                                              marketplaces[
                                                                  order
                                                                      ?.marketplace
                                                              ]?.parentId
                                                          ]?.img
                                                      }
                                                  />
                                                  {!marketplaces[
                                                      order.marketplace
                                                  ]?.img &&
                                                  marketplaces[
                                                      order?.marketplace
                                                  ]?.parentId ? (
                                                      <div className="sub-marketplace-name">
                                                          {
                                                              marketplaces[
                                                                  order
                                                                      .marketplace
                                                              ]?.name
                                                          }
                                                      </div>
                                                  ) : (
                                                      <></>
                                                  )}
                                              </div>
                                          </>
                                      ) : (
                                          ""
                                      )}
                                      <div
                                          className={`admin-order ${
                                              focused && focused.id === order.id
                                                  ? "focused"
                                                  : ""
                                          } ${
                                              order.user?.cutHourActive ===
                                                  "1" &&
                                              order.marketplace === "1"
                                                  ? "cut-hour"
                                                  : ""
                                          }`}
                                          tabIndex={1}
                                          onFocus={() => {
                                              setFocused(order);
                                          }}
                                          key={order.id}
                                          style={{
                                              animationDelay: `${i * 50}ms`,
                                              borderColor:
                                                  order.marketplace !== null &&
                                                  marketplaces[
                                                      order.marketplace
                                                  ]
                                                      ? marketplaces[
                                                            order.marketplace
                                                        ].color
                                                      : "#fff",
                                          }}
                                          ref={(el) =>
                                              (ordersEls.current[i] = el)
                                          }
                                          onClick={() => {
                                              if (checkIsReady(order)) {
                                                  setOrderStatus(order);
                                              }
                                          }}
                                      >
                                          {/* <div
                                              className="admin-order-border"
                                              style={{
                                                  borderColor:
                                                      order.marketplace && marketplaces[order.marketplace]
                                                          ? marketplaces[order.marketplace].color
                                                          : "#fff",
                                              }}
                                          ></div> */}
                                          <div
                                              className="admin-order-check"
                                              onClick={() => {
                                                  let newOrders = [...orders];
                                                  newOrders[
                                                      newOrders.findIndex(
                                                          (o) =>
                                                              o.id === order.id
                                                      )
                                                  ].checked = !order.checked;
                                                  setOrders(newOrders);
                                              }}
                                          >
                                              <div
                                                  className={`admin-order-checked ${
                                                      order && order.checked
                                                          ? "checked"
                                                          : ""
                                                  }`}
                                              ></div>
                                          </div>
                                          <div className="admin-order-menu">
                                              <CopyDiv
                                                  className="admin-order-number"
                                                  style={{
                                                      backgroundColor:
                                                          checkIsReady(order)
                                                              ? statuses[9]
                                                                    .color
                                                              : statuses[0]
                                                                    .color,
                                                  }}
                                                  value={order.id}
                                              >
                                                  {`${order.id}`.padStart(6, 0)}
                                              </CopyDiv>
                                              <div className="admin-order-btns">
                                                  {isFull(order) ? (
                                                      <>
                                                          {/* Botões para pedidos FULL */}

                                                          <div
                                                              className={
                                                                  order.plpPrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onClick={async () => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.productLabel
                                                                              )
                                                                                  return alert(
                                                                                      "Etiqueta de produto não encontrada"
                                                                                  );
                                                                              setModalPlp(
                                                                                  true
                                                                              );

                                                                              getPlpImage(
                                                                                  order.productLabel
                                                                              ).then(
                                                                                  (
                                                                                      img
                                                                                  ) =>
                                                                                      setPlp(
                                                                                          img
                                                                                      )
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                              onDoubleClick={async () => {
                                                                  const productLabelFile =
                                                                      await fetch(
                                                                          `/uploads/orders/${order.productLabel}`
                                                                      );

                                                                  const productLabelData =
                                                                      await productLabelFile.text();
                                                                  if (
                                                                      !productLabelData
                                                                  )
                                                                      return;

                                                                  await zebraPrint(
                                                                      etqPrinter,
                                                                      productLabelData
                                                                  );
                                                              }}
                                                          >
                                                              Etq. Prod.
                                                          </div>
                                                          <div
                                                              className={
                                                                  order.plpPrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onClick={async () => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.volumeLabel
                                                                              )
                                                                                  return alert(
                                                                                      "Etiqueta de volume não encontrada"
                                                                                  );
                                                                              setModalPlp(
                                                                                  true
                                                                              );

                                                                              getPlpImage(
                                                                                  order.volumeLabel
                                                                              ).then(
                                                                                  (
                                                                                      img
                                                                                  ) =>
                                                                                      setPlp(
                                                                                          img
                                                                                      )
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                              onDoubleClick={async () => {
                                                                  const volumeLabelFile =
                                                                      await fetch(
                                                                          `/uploads/orders/${order.volumeLabel}`
                                                                      );

                                                                  const volumeLabelData =
                                                                      await volumeLabelFile.text();
                                                                  if (
                                                                      !volumeLabelData
                                                                  )
                                                                      return;

                                                                  await zebraPrint(
                                                                      plpPrinter,
                                                                      volumeLabelData
                                                                  );
                                                              }}
                                                          >
                                                              Etq. Vol.
                                                          </div>
                                                          <div
                                                              className={
                                                                  order.nfePrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onDoubleClick={() => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  printNFes([
                                                                      order,
                                                                  ]);
                                                              }}
                                                              onClick={() => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.nfe
                                                                              )
                                                                                  return alert(
                                                                                      "Nota fiscal não encontrada"
                                                                                  );
                                                                              setModalOrderObj(
                                                                                  order
                                                                              );
                                                                              setModalNfe(
                                                                                  true
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                          >
                                                              NFe
                                                          </div>

                                                          <div
                                                              className={
                                                                  order.nfePrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onClick={async () => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.entranceAuth
                                                                              )
                                                                                  return alert(
                                                                                      "Autorização de entrada não encontrada"
                                                                                  );
                                                                              setModalOrderObj(
                                                                                  order
                                                                              );
                                                                              setModalEntranceAuth(
                                                                                  true
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                          >
                                                              Aut. Ent.
                                                          </div>

                                                          <div>
                                                              {new Date(
                                                                  order.fullDateSelection
                                                              ).toLocaleDateString(
                                                                  "pt-BR",
                                                                  {
                                                                      timeZone:
                                                                          "GMT-0",
                                                                  }
                                                              )}
                                                          </div>
                                                      </>
                                                  ) : (
                                                      <>
                                                          <div
                                                              className={
                                                                  order.plpPrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onClick={async () => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.plp
                                                                              )
                                                                                  return alert(
                                                                                      "PLP não encontrada"
                                                                                  );
                                                                              setModalPlp(
                                                                                  true
                                                                              );

                                                                              getPlpImage(
                                                                                  order.plp
                                                                              ).then(
                                                                                  (
                                                                                      img
                                                                                  ) =>
                                                                                      setPlp(
                                                                                          img
                                                                                      )
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                              onDoubleClick={() => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  printPLPs([
                                                                      order,
                                                                  ]);
                                                              }}
                                                          >
                                                              PLP
                                                          </div>

                                                          {/* NFe aparece tanto para FULL quanto não FULL */}
                                                          <div
                                                              className={
                                                                  order.nfePrinted
                                                                      ? "printed"
                                                                      : ""
                                                              }
                                                              onDoubleClick={() => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  printNFes([
                                                                      order,
                                                                  ]);
                                                              }}
                                                              onClick={() => {
                                                                  clearTimeout(
                                                                      clickTimeout
                                                                  );
                                                                  clickTimeout =
                                                                      setTimeout(
                                                                          () => {
                                                                              if (
                                                                                  !order.nfe
                                                                              )
                                                                                  return alert(
                                                                                      "Nota fiscal não encontrada"
                                                                                  );
                                                                              setModalOrderObj(
                                                                                  order
                                                                              );
                                                                              setModalNfe(
                                                                                  true
                                                                              );
                                                                          },
                                                                          200
                                                                      );
                                                              }}
                                                          >
                                                              NFe
                                                          </div>

                                                          <div
                                                              onClick={() => {
                                                                  printLabel([
                                                                      order,
                                                                  ]);
                                                              }}
                                                          >
                                                              Pnf
                                                          </div>
                                                      </>
                                                  )}
                                                  <div
                                                      className=""
                                                      onClick={() => {
                                                          setModalOrder(order);
                                                          setCancelModal(true);
                                                      }}
                                                  >
                                                      Cancelar
                                                  </div>
                                                  {/* {order?.data?.shipment?.logistic_type} */}
                                              </div>
                                              <div className="admin-order-marketplace-img">
                                                  <img
                                                      src={
                                                          marketplaces[
                                                              order.marketplace
                                                          ]?.img ||
                                                          marketplaces[
                                                              marketplaces[
                                                                  order
                                                                      .marketplace
                                                              ]?.parentId
                                                          ]?.img
                                                      }
                                                      alt={
                                                          marketplaces[
                                                              order.marketplace
                                                          ]?.name
                                                      }
                                                  />
                                                  {marketplaces[
                                                      order.marketplace
                                                  ]?.parentId &&
                                                  !marketplaces[
                                                      order.marketplace
                                                  ]?.img ? (
                                                      <span className="admin-marketplace-sub">
                                                          {
                                                              marketplaces[
                                                                  order
                                                                      .marketplace
                                                              ]?.name
                                                          }
                                                      </span>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                              {order.marketplace !== 82 ? (
                                                  order.idBling ? (
                                                      <img
                                                          className="order-bling-icon"
                                                          src="/img/orders/bling.svg"
                                                      />
                                                  ) : order.meliId ? (
                                                      <div className="order-bling-icon">
                                                          AI-stem
                                                      </div>
                                                  ) : (
                                                      <div className="order-bling-icon manual">
                                                          Manual
                                                      </div>
                                                  )
                                              ) : (
                                                  <></>
                                              )}

                                              <div className="admin-order-username">
                                                  {order.user?.name
                                                      ?.split(" ")
                                                      ?.slice(0, 2)
                                                      .join(" ")}
                                                  <div className="admin-order-user">
                                                      <img
                                                          src="/img/einstein-user-logo.svg"
                                                          className="admin-order-user-photo"
                                                          alt="Foto"
                                                      />
                                                      <div className="admin-order-user-email-container">
                                                          <div className="admin-order-user-email">
                                                              {
                                                                  order.user
                                                                      ?.email
                                                              }
                                                          </div>
                                                          <a
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              href={`https://wa.me/55${order?.user?.phone}`}
                                                              className="admin-order-user-whatsapp"
                                                          >
                                                              (
                                                              {order.user?.phone?.slice(
                                                                  0,
                                                                  2
                                                              )}
                                                              ){" "}
                                                              {order.user?.phone?.slice(
                                                                  2,
                                                                  -1
                                                              )}
                                                          </a>
                                                      </div>
                                                  </div>
                                                  {order.user?.cutHourActive ===
                                                      "1" &&
                                                  order.marketplace === "1" ? (
                                                      <div className="user-cut-hour">
                                                          Hora corte:{" "}
                                                          <strong>
                                                              {
                                                                  order.user
                                                                      ?.cutHour
                                                              }
                                                          </strong>
                                                      </div>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                          </div>
                                          <div className="admin-order-products">
                                              {order?.order_products &&
                                                  Object.keys(
                                                      order.order_products
                                                  ).map((productKey) => {
                                                      const orderProduct =
                                                          order.order_products[
                                                              productKey
                                                          ];

                                                      orderProduct.product =
                                                          adminProducts.find(
                                                              (p) =>
                                                                  orderProduct.productId ===
                                                                  p.id
                                                          );

                                                      let clickTimeout;

                                                      if (
                                                          isFull(order) &&
                                                          order?.order_products[
                                                              productKey - 1
                                                          ]?.productId ===
                                                              orderProduct.productId
                                                      )
                                                          return <></>;
                                                      return (
                                                          <Fragment
                                                              key={
                                                                  orderProduct.id
                                                              }
                                                          >
                                                              <div
                                                                  className={`admin-order-product ${
                                                                      (orderProduct.status ==
                                                                          2 ||
                                                                          orderProduct.status ==
                                                                              4) &&
                                                                      new Date() -
                                                                          new Date(
                                                                              orderProduct.updatedAt
                                                                          ) >
                                                                          30 *
                                                                              60 *
                                                                              1000
                                                                          ? "blinking"
                                                                          : ""
                                                                  }`}
                                                                  style={{
                                                                      backgroundColor:
                                                                          statuses[
                                                                              orderProduct
                                                                                  .status
                                                                          ]
                                                                              ?.color,
                                                                      borderColor:
                                                                          statuses[
                                                                              orderProduct
                                                                                  .status
                                                                          ]
                                                                              ?.color,
                                                                  }}
                                                                  onMouseEnter={() =>
                                                                      setHovered(
                                                                          true
                                                                      )
                                                                  }
                                                                  onMouseLeave={() =>
                                                                      setHovered(
                                                                          false
                                                                      )
                                                                  }
                                                                  onDoubleClick={(
                                                                      ev
                                                                  ) => {
                                                                      clearTimeout(
                                                                          clickTimeout
                                                                      );
                                                                      zebraPrint(
                                                                          etqPrinter,
                                                                          getEtq(
                                                                              orderProduct
                                                                                  .product
                                                                                  .id,
                                                                              orderProduct
                                                                                  .product
                                                                                  .sku,
                                                                              orderProduct
                                                                                  .product
                                                                                  .name
                                                                          )
                                                                      );
                                                                  }}
                                                                  onClick={() => {
                                                                      clearTimeout(
                                                                          clickTimeout
                                                                      );
                                                                      clickTimeout =
                                                                          setTimeout(
                                                                              () => {
                                                                                  //setProductStatus(9, orderProduct.id, order.id)
                                                                              },
                                                                              200
                                                                          );
                                                                  }}
                                                                  key={
                                                                      orderProduct.id
                                                                  }
                                                              >
                                                                  {order.marketplace ===
                                                                  82 ? (
                                                                      <div className="admin-order-qtty">
                                                                          {
                                                                              orderProduct.qtty
                                                                          }
                                                                      </div>
                                                                  ) : (
                                                                      <></>
                                                                  )}
                                                                  <img
                                                                      src={`https://orizom.com.br/uploads/thumbs/${orderProduct.product.photos[0]}`}
                                                                      alt={
                                                                          orderProduct.sku
                                                                      }
                                                                  />
                                                                  <div className="admin-order-sku">
                                                                      {
                                                                          orderProduct
                                                                              .product
                                                                              .sku
                                                                      }
                                                                  </div>
                                                                  <img
                                                                      className="expand"
                                                                      src={`https://orizom.com.br/uploads/thumbs/${orderProduct.product.photos[0]}`}
                                                                      alt={
                                                                          orderProduct.sku
                                                                      }
                                                                  />
                                                                  {orderProduct.status !==
                                                                  "9" ? (
                                                                      <div className="admin-order-product-btns">
                                                                          <div
                                                                              className={`${
                                                                                  orderProduct.status ===
                                                                                  "1"
                                                                                      ? "disabled"
                                                                                      : ""
                                                                              }`}
                                                                              onClick={(
                                                                                  ev
                                                                              ) => {
                                                                                  ev.stopPropagation();
                                                                                  setProductStatus(
                                                                                      1,
                                                                                      orderProduct.id,
                                                                                      order.id
                                                                                  );
                                                                              }}
                                                                          >
                                                                              Solicitar
                                                                              do
                                                                              estoque
                                                                          </div>
                                                                          <div
                                                                              className={`engrave ${
                                                                                  orderProduct.status ===
                                                                                  "3"
                                                                                      ? "disabled"
                                                                                      : ""
                                                                              }`}
                                                                              onClick={(
                                                                                  ev
                                                                              ) => {
                                                                                  ev.stopPropagation();
                                                                                  setProductStatus(
                                                                                      3,
                                                                                      orderProduct.id,
                                                                                      order.id
                                                                                  );
                                                                              }}
                                                                          >
                                                                              Solicitar
                                                                              gravação
                                                                          </div>

                                                                          <div
                                                                              className={`ready ${
                                                                                  orderProduct.status ===
                                                                                  "4"
                                                                                      ? "disabled"
                                                                                      : ""
                                                                              }`}
                                                                              onClick={(
                                                                                  ev
                                                                              ) => {
                                                                                  ev.stopPropagation();
                                                                                  setProductStatus(
                                                                                      4,
                                                                                      orderProduct.id,
                                                                                      order.id
                                                                                  );
                                                                              }}
                                                                          >
                                                                              Pronto
                                                                          </div>
                                                                      </div>
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                              </div>
                                                              {order.marketplace !==
                                                                  82 &&
                                                                  (Array.isArray(
                                                                      orderProduct.options
                                                                  )
                                                                      ? orderProduct.options
                                                                      : []
                                                                  ).map(
                                                                      (
                                                                          op,
                                                                          index
                                                                      ) => {
                                                                          //   let option = options[op.name];
                                                                          let name,
                                                                              image;

                                                                          switch (
                                                                              op.name
                                                                          ) {
                                                                              case "box":
                                                                                  let box =
                                                                                      adminBoxes.find(
                                                                                          (
                                                                                              b
                                                                                          ) =>
                                                                                              b.id ===
                                                                                              orderProduct
                                                                                                  .product
                                                                                                  .box
                                                                                      );
                                                                                  if (
                                                                                      box
                                                                                  ) {
                                                                                      name =
                                                                                          box.name ||
                                                                                          "Caixa";
                                                                                      image = `/uploads/internal/${box.photo}`;
                                                                                  }
                                                                                  break;
                                                                              case "gift":
                                                                                  name =
                                                                                      "Presente";
                                                                                  image =
                                                                                      "/img/presente3.png";
                                                                                  break;
                                                                              case "name":
                                                                                  name =
                                                                                      op.value;
                                                                                  image =
                                                                                      "/img/burn.png";
                                                                                  break;
                                                                              default:
                                                                                  name =
                                                                                      op.name;
                                                                                  break;
                                                                          }
                                                                          return (
                                                                              <div
                                                                                  className="admin-order-product"
                                                                                  style={{
                                                                                      backgroundColor:
                                                                                          statuses[
                                                                                              op
                                                                                                  .status
                                                                                          ]
                                                                                              .color,
                                                                                  }}
                                                                                  key={
                                                                                      index
                                                                                  }
                                                                              >
                                                                                  {image ? (
                                                                                      <>
                                                                                          <img
                                                                                              src={
                                                                                                  image
                                                                                              }
                                                                                              alt={
                                                                                                  name
                                                                                              }
                                                                                          />
                                                                                          <img
                                                                                              className="expand"
                                                                                              src={
                                                                                                  image
                                                                                              }
                                                                                              alt={
                                                                                                  name
                                                                                              }
                                                                                          />
                                                                                      </>
                                                                                  ) : (
                                                                                      ""
                                                                                  )}
                                                                                  <div className="admin-order-sku">
                                                                                      {
                                                                                          name
                                                                                      }
                                                                                  </div>
                                                                                  {/* {!option?.code ? (
                                                                              <div className="admin-option-confirm">
                                                                                  Confirmar
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )} */}
                                                                              </div>
                                                                          );
                                                                      }
                                                                  )}
                                                          </Fragment>
                                                      );
                                                  })}

                                              <div
                                                  className="admin-order-dates"
                                                  onClick={(ev) => {
                                                      if (ev.detail === 5)
                                                          setOrderStatus(order);
                                                  }}
                                              >
                                                  <div className="admin-order-date">
                                                      Confirmado
                                                      <div className="admin-order-date-number">
                                                          {new Date(
                                                              order?.statuses
                                                                  ?.confirmed ||
                                                                  order?.createdAt
                                                          )
                                                              .toLocaleString(
                                                                  "pt-BR"
                                                              )
                                                              .slice(0, -3)
                                                              .replace(",", "")}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          {order.marketplace === 82 ? (
                                              <div className="admin-full-volumes">
                                                  <div className="admin-full-status">
                                                      Acessórios:{" "}
                                                      <span>
                                                          {
                                                              order.order_products.filter(
                                                                  (e) =>
                                                                      e?.product
                                                                          ?.type !==
                                                                          0 &&
                                                                      e?.product
                                                                          ?.type !==
                                                                          6 &&
                                                                      e?.product
                                                                          ?.type !==
                                                                          10
                                                              ).length
                                                          }
                                                      </span>
                                                  </div>
                                                  <div className="admin-full-status">
                                                      Relógios e outros:{" "}
                                                      <span>
                                                          {
                                                              order.order_products.filter(
                                                                  (e) =>
                                                                      e?.product
                                                                          ?.type ===
                                                                          0 ||
                                                                      e?.product
                                                                          ?.type ===
                                                                          6 ||
                                                                      e?.product
                                                                          ?.type ===
                                                                          10
                                                              ).length
                                                          }
                                                      </span>
                                                  </div>
                                                  <div className="admin-full-status">
                                                      Volumes estimados:{" "}
                                                      <span>
                                                          {Math.ceil(
                                                              order.order_products.filter(
                                                                  (e) =>
                                                                      e?.product
                                                                          ?.type ===
                                                                          0 ||
                                                                      e?.product
                                                                          ?.type ===
                                                                          6 ||
                                                                      e?.product
                                                                          ?.type ===
                                                                          10
                                                              ).length / 4
                                                          ) +
                                                              (order.order_products.some(
                                                                  (e) =>
                                                                      e?.product
                                                                          ?.type !==
                                                                          0 &&
                                                                      e?.product
                                                                          ?.type !==
                                                                          6
                                                              )
                                                                  ? 1
                                                                  : 0)}
                                                      </span>
                                                  </div>
                                              </div>
                                          ) : (
                                              <></>
                                          )}
                                      </div>
                                  </Fragment>
                              );
                          })
                        : ""}
                    {ordersSorted.length ? (
                        <div
                            className="order-marketplace-ending"
                            style={{
                                borderColor: marketplaces[
                                    ordersSorted[ordersSorted.length - 1][1]
                                        .marketplace
                                ]
                                    ? marketplaces[
                                          ordersSorted[
                                              ordersSorted.length - 1
                                          ][1].marketplace
                                      ].color
                                    : "#fff",
                            }}
                        ></div>
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className={`admin-autoprint-container ${
                        autoPrint ? "on" : ""
                    } ${focused === "autoprint" ? "focus" : ""}`}
                    onClick={() => {
                        setFocused("autoprint");
                    }}
                >
                    <div
                        className={`print-all-btn ${
                            printingAll ? "disabled" : ""
                        }`}
                        onClick={() => {
                            if (printingAll) return;
                            setPrintingAll(true);

                            printAll(autoOrders)
                                .then(() => {
                                    setPrintingAll(false);
                                })
                                .catch((err) => {
                                    alert(err);
                                    setPrintingAll(false);
                                });
                        }}
                    >
                        Imprimir tudo
                    </div>
                    {autoOrders?.map((order) => {
                        let clickTimeout;
                        return (
                            <div className="autoprint-order" key={order.id}>
                                <div className="admin-order-btns">
                                    <CopyDiv
                                        target="_blank"
                                        rel="noreferrer"
                                        className="admin-order-number"
                                        style={{
                                            backgroundColor: checkIsReady(order)
                                                ? statuses[9].color
                                                : statuses[0].color,
                                        }}
                                    >
                                        {`${order.id}`.padStart(6, 0)}
                                    </CopyDiv>
                                    <div
                                        className={
                                            order.plpPrinted ? "printed" : ""
                                        }
                                        onDoubleClick={() => {
                                            clearTimeout(clickTimeout);
                                            printPLPs([order]);
                                            //zebraPrint(plpPrinter, `https://orizom.com.br/${order.plp}`, 'pdf');
                                        }}
                                        onClick={async () => {
                                            clearTimeout(clickTimeout);
                                            clickTimeout = setTimeout(() => {
                                                if (!order.plp)
                                                    return alert(
                                                        "PLP não encontrada"
                                                    );
                                                setModalPlp(true);

                                                getPlpImage(order.plp).then(
                                                    (img) => setPlp(img)
                                                );
                                            }, 200);
                                        }}
                                    >
                                        PLP
                                    </div>
                                    <div
                                        className={
                                            order.nfePrinted ? "printed" : ""
                                        }
                                        onDoubleClick={() => {
                                            clearTimeout(clickTimeout);
                                            printNFes([order]);
                                            //zebraPrint(plpPrinter, `https://orizom.com.br/uploads/pdfs${order.nfe}`, 'pdf');
                                            //alert(`https://orizom.com.br/uploads/pdfs${order.nfe}`);
                                        }}
                                        onClick={() => {
                                            clearTimeout(clickTimeout);
                                            clickTimeout = setTimeout(() => {
                                                if (!order.nfe) return;
                                                setModalOrderObj(order);
                                                setModalNfe(true);
                                            }, 200);
                                        }}
                                    >
                                        NFe
                                    </div>
                                    <div
                                        onClick={() => {
                                            printLabel([order]);
                                        }}
                                    >
                                        Pnf
                                    </div>
                                </div>
                                <div className="autoprint-secondrow">
                                    <div className="admin-order-username">
                                        <div className="autoprint-username">
                                            {order.user?.name
                                                ?.split(" ")
                                                ?.slice(0, 2)
                                                .join(" ")}
                                        </div>
                                        <div className="admin-order-user">
                                            <div className="admin-order-user-email-container">
                                                <div className="admin-order-user-email">
                                                    {order.user?.email}
                                                </div>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`https://wa.me/55${order?.user?.phone}`}
                                                    className="admin-order-user-whatsapp"
                                                >
                                                    (
                                                    {order.user?.phone?.slice(
                                                        0,
                                                        2
                                                    )}
                                                    ){" "}
                                                    {order.user?.phone?.slice(
                                                        2,
                                                        -1
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                        {/* {order.user?.cutHourActive === "1" &&
                                        order.marketplace === "1" ? (
                                            <div className="user-cut-hour">
                                                Hora corte:{" "}
                                                <strong>
                                                    {order.user?.cutHour}
                                                </strong>
                                            </div>
                                        ) : (
                                            ""
                                        )} */}
                                    </div>
                                    <div className="autoprint-marketplace-container">
                                        {marketplaces[order.marketplace]
                                            ?.parentId &&
                                        !marketplaces[order.marketplace]
                                            ?.img ? (
                                            <span className="admin-marketplace-sub">
                                                {
                                                    marketplaces[
                                                        order.marketplace
                                                    ]?.name
                                                }
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        <img
                                            className="autoprint-marketplace"
                                            src={
                                                marketplaces[order.marketplace]
                                                    ?.img ||
                                                marketplaces[
                                                    marketplaces[
                                                        order.marketplace
                                                    ]?.parentId
                                                ]?.img
                                            }
                                            alt={
                                                marketplaces[order.marketplace]
                                                    ?.name
                                            }
                                        />
                                        {order.idBling ? (
                                            <img
                                                className="order-bling-icon"
                                                src="/img/orders/bling.svg"
                                            />
                                        ) : order.meliId ? (
                                            <div className="order-bling-icon">
                                                AI-stem
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

                                <div className="autoprint-thirdrow">
                                    <div className="thirdrow-right">
                                        <img
                                            src={`/uploads/thumbs/${
                                                adminProducts?.find(
                                                    (p) =>
                                                        p.id ===
                                                        order?.order_products[0]
                                                            ?.productId
                                                )?.photos[0]
                                            }`}
                                        />
                                        <div className="autoprint-sku">
                                            {
                                                adminProducts?.find(
                                                    (p) =>
                                                        p.id ===
                                                        order?.order_products[0]
                                                            ?.productId
                                                )?.sku
                                            }
                                        </div>
                                    </div>
                                    <div className="thirdrow-left">
                                        <div
                                            className="autoprint-confirm"
                                            onClick={() => {
                                                setOrderStatus(order);
                                            }}
                                        >
                                            Confirmar
                                        </div>
                                        <div
                                            onClick={() => {
                                                setModalOrder(order);
                                                setCancelModal(true);
                                            }}
                                            className={`autoprint-confirm autoprint-cancel`}
                                        >
                                            Cancelar
                                        </div>
                                        <div className="autoprint-date">
                                            {new Date(order.createdAt)
                                                .toLocaleString("br")
                                                .replace(",", "")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className={`autoprint-toggle ${autoPrint ? "on" : ""}`}
                    onClick={() => {
                        setAutoPrint((v) => !v);
                        sessionStorage.setItem("autoPrint", !autoPrint);
                    }}
                >
                    <div className="starbox-qtty">
                        {autoPrint ? (
                            <>
                                {autoOrders?.length || 0} Pedido
                                {autoOrders?.length !== 1 ? "s" : ""}{" "}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* <div> */}
                    <img src="/img/starbox.svg" />
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}
